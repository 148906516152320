import React from 'react';
import { createSelector } from '@reduxjs/toolkit';

import {
  observeDataChanges,
  store,
  setLoadingScreenMessage as setLoadingScreenMessageAction,
  resetLoadingScreenMessage as resetLoadingScreenMessageAction,
} from '@cpce/console-web-store';

import { selectUiStateField, selectApiStateField } from '../helpers/helpers';

const uiLoadingScreenMessageSelector = (state) => selectUiStateField(state, 'loadingScreenMessage');
const uiLoadingScreenVisibleOverrideSelector = (state) => selectUiStateField(state, 'loadingScreenVisibleOverride');

const apiCallInProgressSelector = (state) => selectApiStateField(state, 'inProgress');
const apiCallErrorSelector = (state) => selectApiStateField(state, 'error');

const isLoadingScreenVisibleSelector = createSelector(
  apiCallErrorSelector,
  apiCallInProgressSelector,
  uiLoadingScreenVisibleOverrideSelector,
  (apiCallError, apiCallInProgress, uiLoadingScreenVisibleOverride) =>
    !apiCallError && (uiLoadingScreenVisibleOverride || apiCallInProgress > 0)
);

export const useLoadingScreen = ({ useState, useEffect } = React) => {
  const [loadingScreenProps, setLoadingScreenProps] = useState({ visible: false, message: null });
  const createLoadingScreenProps = (visible) => {
    const message = visible ? uiLoadingScreenMessageSelector(store.getState()) : null;

    setLoadingScreenProps({
      visible,
      message,
    });
  };

  useEffect(() => {
    const unsubscribe = observeDataChanges(store, isLoadingScreenVisibleSelector, createLoadingScreenProps);

    return () => {
      unsubscribe();
    };
  }, []);

  return loadingScreenProps;
};

export const setLoadingScreenMessage = (message) => {
  store.dispatch(setLoadingScreenMessageAction(message));
};

export const resetLoadingScreenMessage = (message) => {
  store.dispatch(resetLoadingScreenMessageAction(message));
};
