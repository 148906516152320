import axios from 'axios';

export const HTTP_METHODS = Object.freeze({
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
});

export default async function coreApiRequest({
  baseUrl = '',
  url,
  method = HTTP_METHODS.GET,
  headers,
  payload,
  uploadProgressHandler,
  downloadProgressHandler,
  cancelToken,
  responseType,
  timeout = 0,
}) {
  const requestOptions = {
    baseUrl,
    url,
    method,
    headers,
    data: payload,
    responseType,
    timeout,
    cancelToken,
    onUploadProgress: uploadProgressHandler,
    onDownloadProgress: downloadProgressHandler,
  };

  return await axios(requestOptions);
}
