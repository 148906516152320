import { get, set, remove } from 'local-storage';

const getItem = (key) => {
  return get(key);
};

const setItem = (key, value) => {
  return set(key, value);
};

const removeItem = (key) => {
  remove(key);
};

export { getItem, setItem, removeItem };
