import { v4 as uuidv4 } from 'uuid';
import base64js from 'base64-js';
import { isEmpty } from 'lodash';

import { store } from '@cpce/console-web-store';
import { HTTP_METHODS } from '@cpce/console-web-core-api';

const traceId = uuidv4().toString();
const ACCESS_TOKEN_ID = 'Access-Token',
  HAYSTACK_TRACE_ID = 'trace-id',
  VAP_CONSOLE_CONTEXT = 'VAP-Console-Context',
  PARTNER_ACCOUNT_ID_HEADER_NAME = 'Partner-Account-Id';

const selectUserProfileForContext = ({
  user: {
    activePartner: {
      profile: { partnerId },
    },
    profile,
    userId,
    role,
  },
}) => ({
  userId,
  partnerId,
  role: profile.role,
  roleId: role,
});

const selectIdToken = ({ token: { idToken } }) => idToken;

const encodeContextData = (contextData) => {
  return !isEmpty(contextData) ? base64js.fromByteArray(Buffer.from(JSON.stringify(contextData))) : '';
};

const getDefaultContextData = () => {
  const { userId, partnerId, role, roleId } = selectUserProfileForContext(store.getState());
  return userId && partnerId && role && roleId
    ? createDefaultContextData({
        userId,
        partnerAccountId: partnerId,
        role,
        roleId,
      })
    : {};
};

const createDefaultContextData = ({ userId, partnerAccountId, role, roleId }) => ({
  userID: [userId],
  partnerAccountId: [partnerAccountId],
  role: [role],
  roleId: [roleId],
});

const addPartnerAccountIdHeader = (contextData, headers) => {
  const { partnerAccountId } = contextData;
  if (partnerAccountId) {
    const [partnerAccountIdValue] = partnerAccountId;
    headers[PARTNER_ACCOUNT_ID_HEADER_NAME] = partnerAccountIdValue;
  }
};

export const generateRequestHeaders = (httpRequest = HTTP_METHODS.GET, additionalHeaders = {}, additionalContextData = {}, payload) => {
  const id_token = selectIdToken(store.getState()) || '';
  const defaultContextData = getDefaultContextData(),
    contextData = {
      ...defaultContextData,
      ...additionalContextData,
    };
  const headers = {
    [HAYSTACK_TRACE_ID]: traceId,
    [VAP_CONSOLE_CONTEXT]: encodeContextData(contextData),
    [ACCESS_TOKEN_ID]: id_token,
  };

  // These addition headers are for POST and PUT HTTP methods.
  const payloadRelatedHeaders = {
    Accept: 'application/json, multipart/form-data',
    'Content-Type': payload instanceof FormData ? 'multipart/form-data' : 'application/json',
  };

  addPartnerAccountIdHeader(contextData, headers);
  const httpMethodsWithoutPayload = [HTTP_METHODS.GET, HTTP_METHODS.DELETE];
  if (httpMethodsWithoutPayload.includes(httpRequest)) {
    return { ...headers, ...additionalHeaders };
  }

  return { ...headers, ...payloadRelatedHeaders, ...additionalHeaders };
};
