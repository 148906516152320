const createFormSchemaState = (data, validationStateSchema) => {
  const propertySchemaList = Object.keys(validationStateSchema);
  return Object.entries(data).reduce((acc, [field, value]) => {
    if (propertySchemaList.includes(field) && !acc[field]) {
      acc[field] = { value, error: '' };
    }
    return acc;
  }, {});
};

export { createFormSchemaState };
