import React from 'react';
import { observeDataChanges, selectUi, store } from '@cpce/console-web-store';

export const useSelectedPartner = ({ useState, useEffect } = React) => {
  const [partnerProps, setPartnerProps] = useState({});

  useEffect(() => {
    const unsubscribe = observeDataChanges(store, selectUi, ({ selectedPartner }) => setPartnerProps(selectedPartner));

    return () => {
      unsubscribe();
    };
  }, []);

  return partnerProps;
};
