export const VERSION_SEPARATOR = '?';

/**
 * Remove version string that follows the resource uri string. Ex: "tasks/123?v1" => "tasks/123"
 *
 * @param {string} resourceUri
 * @param {string} versionSeparator a string that stays in between resource uri and version string
 * @returns {string} resourceUri with no version attached
 */
export const removeVersionFromResourceUri = (resourceUri, versionSeparator = VERSION_SEPARATOR) => {
  if (!resourceUri) {
    return null;
  }
  return resourceUri.split(versionSeparator).shift();
};
