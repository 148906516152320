import { store } from '@cpce/console-web-store';
import { selectSubApps } from '@cpce/console-web-store';

// helper functions
const allSubAppsSelector = (state) => {
  return selectSubApps(state);
};

const currentSubAppFilterBasedOnRoute = (subApps, path) => {
  // skipping the app data configs for widgets. Widgets have no routes defined in app config.
  const result = subApps.find((app) => app.isWidget !== true && app.routes[0] && path.indexOf(app.routes[0]) === 0);

  return result;
};

/**
 * Returns the current sub app
 *
 * @export
 * @returns current sub app deployed into the console configuration
 */
export default function getCurrentSubApp(pathname) {
  const path = pathname ? pathname : window.location.pathname;
  const state = store.getState();
  const subApps = allSubAppsSelector(state);
  const currentApp = currentSubAppFilterBasedOnRoute(subApps, path);
  return currentApp;
}
