import { HTTP_METHODS } from '@cpce/console-web-core-api';
import { apiRequest } from '../';
import { generateRequestHeaders } from '../helpers/generateRequestHeaders';

export default async function apiRequestDefaultHeaders({
  baseUrl,
  url,
  method = HTTP_METHODS.GET,
  payload,
  responseType,
  timeout,
  uploadProgressHandler,
  downloadProgressHandler,
  cancelToken,
  additionalContextData = {},
  additionalHeaders = {},
  customStatusMessage: {
    bannerType = '',
    bannerNote = '',
    fulfilledMessage = '',
    rejectedMessage = '',
    showUnderlyingError = true,
    showError = true,
  } = {},
  loadingScreenMessage = '',
  isLoadingUiOn = true,
}) {
  const headers = generateRequestHeaders(method, additionalHeaders, additionalContextData, payload);
  return await apiRequest({
    baseUrl,
    url,
    method,
    headers,
    payload,
    responseType,
    timeout,
    uploadProgressHandler,
    downloadProgressHandler,
    cancelToken,
    customStatusMessage: {
      bannerType,
      bannerNote,
      fulfilledMessage,
      rejectedMessage,
      showUnderlyingError,
      showError,
    },
    loadingScreenMessage,
    isLoadingUiOn,
  });
}
