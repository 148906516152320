/**
 * Process API response data and return the api response payload data or throw an exception when the error data exists.
 *
 * @param response The response
 * @returns {object} The Payload data for the happy path
 * @throws {Error} The Error object for the sad path
 */
export function processApiResponse(response) {
  const {
    error,
    data,
    payload: { data: responseData },
  } = response;

  if (error) {
    throw new Error(data);
  }

  return responseData;
}
