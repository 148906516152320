import { store, selectUser, selectFeatureToggles, setHasEgIdentityAppAccess } from '@cpce/console-web-store';
import { isAuthorizedApp } from './isAuthorizedApp';
import { setUserAuthorization, addAuthorizedRoute } from '@cpce/console-web-auth-module';
import { importSubApp } from './importSubApp';

const setHasEgIdentityAppAccessHelper = (hasEgIdentityAppAccess) => {
  store.dispatch(setHasEgIdentityAppAccess({ hasEgIdentityAppAccess }));
};

export const loadAppWithAuthorization = (subApp, bookmarkPathKey) => {
  const userData = selectUser(store.getState());
  const { authorizedRoutes, isEGLoginSession } = userData;
  const { name, routes, isEgIdentitySupported } = subApp;
  const featureToggles = selectFeatureToggles(store.getState())?.toggles;
  const isEgIdentityFlow = featureToggles?.isEgIdentityToggleEnabledInAppManager && isEGLoginSession;

  if (hasCurrentLocationPath(authorizedRoutes)) {
    isEgIdentityFlow && isEgIdentitySupported && setHasEgIdentityAppAccessHelper(true);
    return authorizeAndImportSubApp(bookmarkPathKey, name);
  }

  if (isAuthorizedApp(subApp, userData)) {
    if (isEgIdentityFlow) {
      if (isEgIdentitySupported) {
        setHasEgIdentityAppAccessHelper(true);
        addAuthorizedRoute(routes);
        return authorizeAndImportSubApp(bookmarkPathKey, name);
      } else {
        setHasEgIdentityAppAccessHelper(false);
        return Promise.reject('This app is not supported in EG Identity flow');
      }
    } else {
      addAuthorizedRoute(routes);
      return authorizeAndImportSubApp(bookmarkPathKey, name);
    }
  } else {
    isEgIdentityFlow && setHasEgIdentityAppAccessHelper(!!isEgIdentitySupported);
    const isSubAppActive = hasCurrentLocationPath(routes);
    if (isSubAppActive) {
      setUserAuthorization(false, bookmarkPathKey);
    }

    return Promise.reject('User Not Authorized');
  }
};

const hasCurrentLocationPath = (routes) => {
  const currentLocation = window.location.pathname;

  return routes?.some((path) => currentLocation.indexOf(path) === 0);
};

const authorizeAndImportSubApp = (bookmarkPathKey, subAppName) => {
  setUserAuthorization(true, bookmarkPathKey);
  return importSubApp({ name: subAppName });
};
