import React from 'react';
import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { resetStatus, resetStatusComplete, observeDataChanges, store } from '@cpce/console-web-store';

import { selectUiStateField, defaultShowMessageData } from '../helpers/helpers';

const STATUS_VISIBILITY_ANIMATION_DURATION = 225;

const uiStatusSelector = (state) => selectUiStateField(state, 'status');

const openNotificationSelector = createSelector(uiStatusSelector, ({ message, visible }) => !isEmpty(message) && visible);

export const useNotification = ({ useState, useEffect } = React) => {
  let timeoutId;
  const [notificationProps, setNotificationProps] = useState({
    open: false,
    bannerType: defaultShowMessageData.bannerType,
    bannerNote: '',
    message: '',
    actualErrorMessages: [],
    autoHideDuration: 0,
  });

  const resetStatusData = () => {
    store.dispatch(resetStatus());

    timeoutId = setTimeout(() => {
      store.dispatch(resetStatusComplete());
    }, STATUS_VISIBILITY_ANIMATION_DURATION);
  };

  const createNotificationProps = (open) => {
    const { bannerType = defaultShowMessageData.bannerType, bannerNote, message, actualErrorMessages, autoHideDuration } = uiStatusSelector(
      store.getState()
    );
    setNotificationProps({
      open,
      bannerType,
      bannerNote,
      message,
      actualErrorMessages,
      autoHideDuration,
      onClose: () => resetStatusData(),
    });
  };

  useEffect(() => {
    const unsubscribe = observeDataChanges(store, openNotificationSelector, createNotificationProps);

    return () => {
      timeoutId && clearTimeout(timeoutId);
      unsubscribe();
    };
  }, []);

  return notificationProps;
};
