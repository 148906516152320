import { store, setRolePreferences as storeRolePreferences } from '@cpce/console-web-store';
import {
  apiRequestDefaultHeaders,
  generateUrl,
  processApiResponse,
  ROLE_PREFERENCES_PATH,
} from '../helpers/helpers';

export const setRolePreferences = async (payload) => {

  const url = generateUrl(ROLE_PREFERENCES_PATH, {});

  const { id, altId, preferences = {} } = processApiResponse(
    await apiRequestDefaultHeaders({
      url,
      method: 'PUT',
      payload,
    })
  );

  store.dispatch(
    storeRolePreferences({
      isLoaded: true,
      rolePreferences: { id, altId, preferences },
    })
  );
};
