import URITemplate from 'urijs/src/URITemplate';
import URI from 'urijs';
import { apiRequestDefaultHeaders } from '@cpce/console-web-api-module';
import { store, selectRolePreferences } from '@cpce/console-web-store';

const ROLE_PREFERENCES_ENDPOINT = '/api/v1/rolepreferencesconfig';
const ROLE_PREFERENCES_PATH = '/rolepreferences';

const generateUrl = (templateUrlPath, queryStringList = {}) => {
  const urlPath = new URITemplate(templateUrlPath).expand({});
  const uri = new URI(`${ROLE_PREFERENCES_ENDPOINT}${urlPath}`);

  for (const queryStringItem in queryStringList) {
    uri.addQuery({ [queryStringItem]: queryStringList[queryStringItem] });
  }

  return uri.href();
};

const processApiResponse = (response) => {
  const {
    error,
    data,
    payload: { data: responseData },
  } = response;

  if (error) {
    throw new Error(data);
  }

  return responseData;
};

const getRolePreferencesState = () => selectRolePreferences(store.getState());

export {
  apiRequestDefaultHeaders,
  generateUrl,
  getRolePreferencesState,
  processApiResponse,
  ROLE_PREFERENCES_ENDPOINT,
  ROLE_PREFERENCES_PATH,
};
