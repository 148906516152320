import { store, setStatus } from '@cpce/console-web-store';
import { defaultShowMessageData } from '../helpers/helpers';

const { message: defaultMessage, bannerType: defaultBannerType, autoHideDuration: defaultAutoHideDuration } = defaultShowMessageData;

export const showNotification = ({
  message = defaultMessage,
  autoHideDuration = defaultAutoHideDuration,
  bannerType = defaultBannerType,
}) => {
  const payload = {
    visible: true,
    autoHideDuration,
    bannerType,
    message,
  };

  store.dispatch(setStatus(payload));
};
