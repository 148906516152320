const PARTNERS = 'partners';
const BUSINESS_SEGMENTS = 'businesssegments';
const CHANNEL_ORIGINS = 'channelorigins';

export const generateSegmentOrChannelOriginLevelContainerUri = (partnerAccountId, segmentId, channelOriginId) => {
  if (partnerAccountId && segmentId && !channelOriginId) {
    return `/${PARTNERS}/${partnerAccountId}/${BUSINESS_SEGMENTS}/${segmentId}`;
  } else if (partnerAccountId && segmentId && channelOriginId) {
    return `/${PARTNERS}/${partnerAccountId}/${BUSINESS_SEGMENTS}/${segmentId}/${CHANNEL_ORIGINS}/${channelOriginId}`;
  } else {
    return undefined;
  }
};
