import { createSlice } from '@reduxjs/toolkit';

const UI_SLICE_NAME = 'ui';

const initialState = {
  selectedPartner: {},
  loadingScreenMessage: '',
  loadingScreenVisibleOverride: false,
  status: {},
  confirmPartnerSwitch: null,
};

const reducers = {
  setSelectedPartner: (state, { payload }) => {
    state.selectedPartner = payload || {};
  },
  setLoadingScreenMessage: (state, { payload }) => {
    state.loadingScreenMessage = payload;
  },
  setLoadingScreenVisibleOverride: (state, { payload }) => {
    state.loadingScreenVisibleOverride = !!payload;
  },
  setConfirmPartnerSwitch: (state, { payload }) => {
    state.confirmPartnerSwitch = payload || null;
  },
  setStatus: (state, { payload }) => {
    state.status = { ...payload };
  },
  resetSelectedPartner: (state) => {
    state.selectedPartner = {};
  },
  resetLoadingScreenMessage: (state) => {
    state.loadingScreenMessage = '';
  },
  resetStatus: (state) => {
    state.status = { ...state.status, visible: false };
  },
  resetStatusComplete: (state) => {
    state.status = {};
  },
  resetUi: () => {
    return initialState;
  },
};

const uiSlice = createSlice({
  name: UI_SLICE_NAME,
  initialState,
  reducers,
});

export const selectUi = (state) => state.ui;
export const uiReducer = uiSlice.reducer;
export const {
  setSelectedPartner,
  setLoadingScreenMessage,
  setLoadingScreenVisibleOverride,
  setStatus,
  resetSelectedPartner,
  setConfirmPartnerSwitch,
  resetLoadingScreenMessage,
  resetStatus,
  resetStatusComplete,
  resetUi,
} = uiSlice.actions;
