import { setItemToLocalStorage, getItemFromLocalStorage } from '@cpce/console-web-utilities';
import React from 'react';
import { refreshTokens, refreshEgPrincipalToken } from '../../auth-module/authModule';

const MOUSE_MOVE_EVENT = 'mousemove';
const CLICK_EVENT = 'click';
const VISIBILITY_CHANGE_EVENT = 'visibilitychange';
const LAST_ACTIVITY_IN_FEDERATED_SESSION_COOKIE_NAME = 'lastActivityInFederatedSession';

const SECONDS_PER_MINUTE = 60;
const MILLIS_PER_SECOND = 1000;
const LOGOUT_BEFORE_TOKEN_EXPIRES_IN_SECONDS = 5;

const INITIAL_SESSION_STATE = { show: false, onCloseModal: () => {} };
const EXPIRED_SESSION_MESSAGE = 'Your session has expired. You must login again before continuing.';
const EXPIRED_FEDERATED_SESSION_MESSAGE = 'Your session has expired. Please click Go Back to return to the Open World home page';
const SESSION_EXPIRED_LOG = 'Logging session expired dialog visibility';
const OPAQUE_TOKEN_EXPIRED_LOG = 'Showing dialog since opaque token is expired';
const REFRESH_EG_PRINCIPAL_TOKEN_FAILED_LOG = 'Showing dialog since eg principal token failed to be refreshed';

export const useSessionValidator = (
  { useState, useEffect, useRef } = React,
  configData,
  isLoggedIn,
  tokenData,
  disallowBookmarkingForPaths,
  featureToggles,
  sendLog,
  isFederatedSession,
  isEGLoginSession
) => {
  const {
    SHOW_NOTIFICATION_BEFORE_TOKEN_EXPIRES_IN_MINS,
    BOOK_MARK_PATH_KEY,
    FEDERATED_SESSION_START_TIMESTAMP,
    SHOW_NOTIFICATION_BEFORE_FEDERATED_SESSION_TOKEN_EXPIRES_IN_MINS,
  } = configData;
  const notificationShowTimeInSeconds = parseInt(SHOW_NOTIFICATION_BEFORE_TOKEN_EXPIRES_IN_MINS) * SECONDS_PER_MINUTE;
  const federatedSessionTokensExpireNotificationShowInSecs =
    parseInt(SHOW_NOTIFICATION_BEFORE_FEDERATED_SESSION_TOKEN_EXPIRES_IN_MINS) * SECONDS_PER_MINUTE;
  const [sessionState, setSessionState] = useState(INITIAL_SESSION_STATE);
  const [sessionMessage, setSessionMessage] = useState('');
  const notificationTimerId = useRef();
  const countdownTimerId = useRef();
  const opaqueTokenNotificationTimerId = useRef();

  const resetNotificationTimer = () => {
    clearTimeout(notificationTimerId.current);
  };

  const resetCountdownTimer = () => {
    clearInterval(countdownTimerId.current);
  };

  const resetOpaqueTokenNotificationTimer = () => {
    clearTimeout(opaqueTokenNotificationTimerId.current);
  };

  const resetNotificationState = () => {
    resetNotificationTimer();
    resetCountdownTimer();
    resetOpaqueTokenNotificationTimer();
    setSessionMessage('');
    setSessionState(INITIAL_SESSION_STATE);
  };

  const logoutSession = () => {
    resetNotificationState();
    const { pathname, search } = window.location;

    if (!disallowBookmarkingForPaths.includes(pathname)) {
      setItemToLocalStorage(BOOK_MARK_PATH_KEY, pathname + search);
    }

    window.history.replaceState('', 'logout', '/logout');
  };

  const autoLogOut = () => {
    const timeInterval = setInterval(() => {
      const lastActivity = localStorage.getItem('lastActivity');
      const diffMs = Math.abs(new Date(lastActivity) - new Date()); // milliseconds between now & last activity
      const seconds = Math.floor(diffMs / 1000);
      const minute = Math.floor(seconds / 60);
      if (lastActivity && minute >= 2) {
        clearInterval(timeInterval);
        logoutSession();
      }
    }, 1000);
  };

  const showSessionExpiredNotification = (logDetails = SESSION_EXPIRED_LOG) => {
    setSessionMessage(isFederatedSession ? EXPIRED_FEDERATED_SESSION_MESSAGE : EXPIRED_SESSION_MESSAGE);
    sendLog(`useSessionValidator: ${logDetails}`);
    setSessionState({
      actionButtonText: isFederatedSession ? 'Go Back' : 'Login',
      behavior: 'confirm',
      cancelButtonText: null,
      onCloseModal: isFederatedSession ? () => null : logoutSession,
      onAccept: logoutSession,
      show: true,
      title: 'Session Expired',
      variant: 'primary',
    });
    setLastActivity();
    !isFederatedSession && autoLogOut();
  };

  const startNotificationTimer = () => {
    resetNotificationTimer();

    refreshTokens(tokenData, configData);
    return sessionState;
  };

  const showFederatedSessionTokenExpiredNotification = (log) => {
    resetOpaqueTokenNotificationTimer();
    showSessionExpiredNotification(log);
  };

  const checkTokenStatus = () => {
    resetNotificationState(); // reset SessionState

    if (isLoggedIn && tokenData?.exp && !(isFederatedSession || isEGLoginSession)) {
      const timestampInSeconds = Math.floor(Date.now() / MILLIS_PER_SECOND);
      const timeBeforeTokenExpiresInSeconds = tokenData.exp - LOGOUT_BEFORE_TOKEN_EXPIRES_IN_SECONDS - timestampInSeconds;
      const notificationTimeout = (timeBeforeTokenExpiresInSeconds - notificationShowTimeInSeconds) * MILLIS_PER_SECOND; // exp is in NumericDate which are in seconds

      if (timeBeforeTokenExpiresInSeconds > 0) {
        notificationTimerId.current = setTimeout(startNotificationTimer, notificationTimeout > 0 ? notificationTimeout : 0);
      } else {
        showSessionExpiredNotification();
      }
    } else if (isLoggedIn && tokenData?.opaqueTokenExp && tokenData?.exp && (isFederatedSession || isEGLoginSession)) {
      const currTimestampInMillis = Date.now();
      const opaqueTokenStartTimestampInMillis = getItemFromLocalStorage(FEDERATED_SESSION_START_TIMESTAMP);
      const opaqueTokenTimePassedInMillis = currTimestampInMillis - opaqueTokenStartTimestampInMillis;
      const opaqueTokenExpiresInMillis =
        tokenData?.opaqueTokenExp * MILLIS_PER_SECOND -
        LOGOUT_BEFORE_TOKEN_EXPIRES_IN_SECONDS * MILLIS_PER_SECOND -
        opaqueTokenTimePassedInMillis;
      const showOpaqueTokenExpiredNotificationTimeoutDelay =
        opaqueTokenExpiresInMillis - federatedSessionTokensExpireNotificationShowInSecs * MILLIS_PER_SECOND;

      if (opaqueTokenExpiresInMillis > 0) {
        opaqueTokenNotificationTimerId.current = setTimeout(
          () => showFederatedSessionTokenExpiredNotification(OPAQUE_TOKEN_EXPIRED_LOG),
          showOpaqueTokenExpiredNotificationTimeoutDelay > 0 ? showOpaqueTokenExpiredNotificationTimeoutDelay : 0
        );
      } else {
        showFederatedSessionTokenExpiredNotification(OPAQUE_TOKEN_EXPIRED_LOG);
      }
    }
  };

  const setLastActivity = () => {
    localStorage.setItem('lastActivity', new Date());
  };

  const validateEgPrincipleToken = async () => {
    const lastActivityInMillis = getItemFromLocalStorage(LAST_ACTIVITY_IN_FEDERATED_SESSION_COOKIE_NAME);
    const currTimestampInMillis = Date.now();
    const timePassedInMillisSinceLastActivity = currTimestampInMillis - lastActivityInMillis;

    if (timePassedInMillisSinceLastActivity > MILLIS_PER_SECOND) {
      setItemToLocalStorage(LAST_ACTIVITY_IN_FEDERATED_SESSION_COOKIE_NAME, currTimestampInMillis);
      const currTimestampInSecs = Math.floor(currTimestampInMillis / MILLIS_PER_SECOND);
      const principalTokenExpiresInSeconds = tokenData?.exp - currTimestampInSecs;

      if (principalTokenExpiresInSeconds <= 0) {
        await refreshEgPrincipalToken(configData).catch(() => {
          showFederatedSessionTokenExpiredNotification(REFRESH_EG_PRINCIPAL_TOKEN_FAILED_LOG);
        });
      }
    }
  };

  useEffect(() => {
    if (!isLoggedIn || !(isFederatedSession || isEGLoginSession) || !tokenData?.exp) {
      return;
    }

    document.addEventListener(MOUSE_MOVE_EVENT, validateEgPrincipleToken);
    document.addEventListener(CLICK_EVENT, validateEgPrincipleToken);
    document.addEventListener(VISIBILITY_CHANGE_EVENT, validateEgPrincipleToken);

    return () => {
      document.removeEventListener(MOUSE_MOVE_EVENT, validateEgPrincipleToken);
      document.removeEventListener(CLICK_EVENT, validateEgPrincipleToken);
      document.removeEventListener(VISIBILITY_CHANGE_EVENT, validateEgPrincipleToken);
    };
  }, [isLoggedIn, isFederatedSession, isEGLoginSession, tokenData]);

  useEffect(() => {
    document.addEventListener(MOUSE_MOVE_EVENT, setLastActivity);
    document.addEventListener(CLICK_EVENT, setLastActivity);

    return function cleanupListener() {
      window.removeEventListener(MOUSE_MOVE_EVENT, setLastActivity);
      window.removeEventListener(CLICK_EVENT, setLastActivity);
    };
  }, [featureToggles]);

  useEffect(() => {
    // initiate the event handler
    document.addEventListener(VISIBILITY_CHANGE_EVENT, checkTokenStatus);
    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      document.removeEventListener(VISIBILITY_CHANGE_EVENT, checkTokenStatus);
    };
  });

  useEffect(() => {
    checkTokenStatus();
  }, [isLoggedIn, tokenData, featureToggles]);

  useEffect(() => {
    return () => {
      resetNotificationTimer();
      resetCountdownTimer();
      resetOpaqueTokenNotificationTimer();
    };
  }, []);

  return [sessionState, sessionMessage];
};
