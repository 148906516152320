import React from 'react';
import { selectTrustedPartners } from '@cpce/console-web-store';

import { onTrustedPartnersChanged } from '..';

export const useTrustedPartners = ({ useState, useEffect } = React) => {
  const [trustedPartners, setTrustedPartners] = useState([]);

  const setData = (updatedData) => {
    setTrustedPartners(updatedData);
  };

  useEffect(() => {
    const unsubscribe = onTrustedPartnersChanged(setData, selectTrustedPartners);

    return () => {
      unsubscribe();
    };
  }, []);

  return trustedPartners;
};
