import * as React from 'react';
import { store, setFeatureToggles, observeDataChanges, selectUser, selectField } from '@cpce/console-web-store';
import { apiRequestDefaultHeaders } from '@cpce/console-web-api-module';
import URI from 'urijs';

function userProfileSelector(state) {
  return selectField(state, selectUser, 'activePartner.profile');
}

function userIdSelector(state) {
  return selectField(state, selectUser, 'userId');
}

function sortFeatureToggleEntries(featureToggles) {
  return Object.keys(featureToggles)
    .sort()
    .reduce((acc, key) => {
      acc[key] = featureToggles[key];
      return acc;
    }, {});
}

async function getFeatureToggles(url) {
  const response = await apiRequestDefaultHeaders({
    url,
    customStatusMessage: {
      rejectedMessage: 'Unable to fetch feature toggles.',
    },
    isLoadingUiOn: false,
    additionalHeaders: {
      'x-bypass-cgp': true,
    },
  });

  const {
    error,
    data,
    payload: { data: dataResponse },
  } = response;
  if (error) {
    throw new Error(data);
  }

  const orderedFeatureToggles = sortFeatureToggleEntries(dataResponse);

  store.dispatch(setFeatureToggles({ toggles: orderedFeatureToggles }));
}

const selectUserProfileForContext = ({
  user: {
    activePartner: {
      profile: { partnerId },
    },
    profile,
    userId,
    role,
  },
}) => ({
  userId,
  partnerAccountId: partnerId,
  role: profile.role,
  roleId: role,
});

async function fetchFeatureToggles(url, sendLogFn) {
  try {
    const contextParams = selectUserProfileForContext(store.getState());
    const uri = new URI(url);

    for (const key in contextParams) {
      if (contextParams[key]) {
        uri.addQuery({ [key]: contextParams[key] });
      }
    }

    await getFeatureToggles(uri.href());
  } catch (error) {
    sendLogFn?.(`Failed to fetch feature toggles - Error: ${error.message}`);
  }
}

export function useFeatureTogglesService(configData, { isLoggedIn }, sendLogFn, { useState, useEffect, useCallback, useRef } = React) {
  const { FEATURE_TOGGLES_REFRESH_INTERVAL_MS: refreshIntervalMs, BACK_END_FT_ENDPOINT } = configData;
  const [intervalId, setIntervalId] = useState(0);
  const [hasUserDataUpdated, setHasUserDataUpdated] = useState(false);
  const isMounted = useRef(true);

  const stopFeatureTogglePolling = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(0);
    }
  }, [intervalId]);

  const startFeatureTogglePolling = useCallback(() => {
    if (!intervalId && refreshIntervalMs) {
      const currentIntervalId = setInterval(() => fetchFeatureToggles(BACK_END_FT_ENDPOINT, sendLogFn), refreshIntervalMs);
      setIntervalId(currentIntervalId);
    }
  }, [BACK_END_FT_ENDPOINT, intervalId, refreshIntervalMs, sendLogFn]);

  const handleUserDataUpdated = useCallback(() => {
    if (isMounted.current) {
      setHasUserDataUpdated(true);
    }
  }, []);

  useEffect(() => {
    const unsubscribeUserProfile = observeDataChanges(store, userProfileSelector, handleUserDataUpdated);
    const unsubscribeUserIdSelector = observeDataChanges(store, userIdSelector, handleUserDataUpdated);

    return () => {
      isMounted.current = false;
      unsubscribeUserProfile();
      unsubscribeUserIdSelector();
    };
  }, [handleUserDataUpdated]);

  useEffect(() => {
    (async (url, sendLog) => {
      if (hasUserDataUpdated) {
        await fetchFeatureToggles(url, sendLog);
        setHasUserDataUpdated(false);
      }
    })(BACK_END_FT_ENDPOINT, sendLogFn);
  }, [BACK_END_FT_ENDPOINT, hasUserDataUpdated, sendLogFn]);

  useEffect(() => {
    if (isLoggedIn) {
      startFeatureTogglePolling();
    } else {
      stopFeatureTogglePolling();
    }

    return () => {
      stopFeatureTogglePolling();
    };
  }, [isLoggedIn, stopFeatureTogglePolling, startFeatureTogglePolling]);

  return null;
}
