export default {
  ABLY_SIGNED_TOKEN_REQUEST_ENDPOINT: '/api/v1/debugger/events/auth',
  ABLY_ENVIRONMENT: 'expedia',
  ABLY_FALLBACK_HOSTS: [
    'expedia-a-fallback.ably-realtime.com',
    'expedia-b-fallback.ably-realtime.com',
    'expedia-c-fallback.ably-realtime.com',
    'expedia-d-fallback.ably-realtime.com',
    'expedia-e-fallback.ably-realtime.com',
  ],
};
