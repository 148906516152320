import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reduxBatch } from '@manaflair/redux-batch';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import { reduxDevToolsOptionsForProduction } from './devToolsOptions';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['api'],
};

const reduxDevToolsBaseConfig = { name: 'Conversation Platform Console Store' };

// This middleware options are to ignore the serialization warning from the redux-persist and thunk function
// Ref: https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
// and https://stackoverflow.com/questions/59773345/react-toolkit-and-redux-first-router
const REDUX_PERSIST_ACTIONS_IGNORE_LIST = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];
const CUSTOM_ACTIONS_IGNORE_LIST = ['api/makeApiRequest/fulfilled', 'api/makeApiRequest/rejected'];
const middlewareOptions = {
  serializableCheck: {
    ignoredActions: [...REDUX_PERSIST_ACTIONS_IGNORE_LIST, ...CUSTOM_ACTIONS_IGNORE_LIST],
  },
};

// Exclude the Redux Persist actions
// Ref: https://github.com/AOHUA/redux-state-sync#before-you-use
// As well as the custom actions related to API request call
const reduxStateSyncConfig = {
  blacklist: [...REDUX_PERSIST_ACTIONS_IGNORE_LIST, ...CUSTOM_ACTIONS_IGNORE_LIST, 'api/makeApiRequest/pending'],
};

export const setupStoreWithPersistor = (rootReducer) => {
  const reducer = persistReducer(persistConfig, rootReducer);
  const store = configureStore({
    middleware: getDefaultMiddleware({
      ...middlewareOptions,
    }).concat(createStateSyncMiddleware(reduxStateSyncConfig)),
    reducer,
    devTools:
      process.env.NODE_ENV === 'production'
        ? { ...reduxDevToolsBaseConfig, ...reduxDevToolsOptionsForProduction }
        : reduxDevToolsBaseConfig,
    enhancers: [reduxBatch],
  });

  persistStore(store);
  initMessageListener(store);
  return store;
};
