import React from 'react';
import { selectToken } from '@cpce/console-web-store';
import { onTokenChanged } from '..';

export const useToken = ({ useState, useEffect, useRef } = React) => {
  const isMounted = useRef(true);

  const [token, setToken] = useState({
    idToken: '',
    refreshToken: '',
    isValid: false,
    exp: 0,
    expDateTime: undefined,
    lastReceived: 0,
    lastReceivedDateTime: undefined,
  });

  const setData = (updatedTokenData) => {
    if (isMounted.current) {
      setToken({
        ...updatedTokenData,
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onTokenChanged(setData, selectToken);

    return () => {
      isMounted.current = false;
      unsubscribe();
    };
  }, []);

  return token;
};
