import { HTTP_METHODS } from '@cpce/console-web-api-module';
import { RESOURCE_ID_PATH, API_TYPE_ENDPOINTS } from '../constants';
import { apiRequest, processApiResponse, generateUrlBasedOnConfigKey } from '../helpers/helpers';

const generateResourceManagementUrl = (templateUrlPath, urlPathList, queryStringList) =>
  generateUrlBasedOnConfigKey(API_TYPE_ENDPOINTS.RESOURCE_MANAGEMENT_ENDPOINT, templateUrlPath, urlPathList, queryStringList);

export const updateResource = async ({ partnerAccountId, resourceId, resourceType, containerUri, payload }) => {
  const url = generateResourceManagementUrl(RESOURCE_ID_PATH, { resourceId, resourceType });

  const body = {
    payload,
    options: {
      containerUri,
      partnerAccountId,
    },
  };

  return processApiResponse(await apiRequest({ url, method: HTTP_METHODS.PUT, payload: body }));
};
