import { isObject } from 'lodash';
import { generateUrl } from './generateUrl';

/**
 * Generate URL based on the configKey, url path template and query string.
 *
 * @param {string} config The config data
 * @param {string} configKey The config key that contains the base path url
 * @param {string} templateUrlPath The url path template string
 * @param {object} [urlPathList={}] The url path object to generate the url path
 * @param {object} [queryStringList={}] The query string object to generate the query string
 * @returns {string} The generated URL
 */
export function generateUrlBasedOnConfigKey(config, configKey, templateUrlPath, urlPathList = {}, queryStringList = {}) {
  if (config && isObject(config) && configKey && typeof configKey === 'string') {
    const basePathUrl = config[configKey];

    if (basePathUrl) {
      return generateUrl(basePathUrl, templateUrlPath, urlPathList, queryStringList);
    }
  }
  throw new Error('Invalid config or missing config key or basePathUrl does not exist in the config');
}
