import * as React from 'react';
import { configureAbly, useChannel } from '@ably-labs/react-hooks';
import { sendLog } from '@cpce/console-web-utilities';
import { parseJSONOrReturnFalse } from '../helpers/helpers';
import { getConfig } from '@cpce/console-web-config';
import configList from '../../config';

/**
 *
 * @param channelName
 * @param conversationId: if conversation id is given - will receive message events for that specific id.
 * @param tokenRequestEndpoint: custom auth endpoint to create ably token request. If not provided, will use console's back-end endpoint by default.
 * @param onMessageEvent: callback function to listen and process any new messages attached to the subscribed event.
 * @param numberOfMessagesToRewind: number of messages to rewind from the current time.
 * @returns {parsedEventData: *[], eventsData: *[]}
 */

export const useAblyConsumer = ({ channelName, conversationId, onMessageEvent, tokenRequestEndpoint, numberOfMessagesToRewind }) => {
  const configData = getConfig(configList);
  const { ABLY_SIGNED_TOKEN_REQUEST_ENDPOINT } = configData;
  const { ABLY_ENVIRONMENT } = configData;
  const { ABLY_FALLBACK_HOSTS } = configData;
  const currentFlavorBaseUrl = window.location.origin;
  const defaultAuthUrl = `${currentFlavorBaseUrl}${ABLY_SIGNED_TOKEN_REQUEST_ENDPOINT}`;

  configureAbly({
    authUrl: tokenRequestEndpoint ? `${currentFlavorBaseUrl}${tokenRequestEndpoint}` : defaultAuthUrl,
    environment: ABLY_ENVIRONMENT,
    fallbackHosts: ABLY_FALLBACK_HOSTS,
  });

  const [channel, ably] = useChannel(`[?rewind=${numberOfMessagesToRewind}]${channelName}`);

  React.useEffect(() => {
    channel.subscribe(conversationId, (message) => {
      const { extras } = message;
      const { headers } = extras;
      const { schemaName } = headers;
      const parsedEventData = parseJSONOrReturnFalse(message.data);

      if (parsedEventData) {
        onMessageEvent(schemaName, parsedEventData);
      }
    });

    return () => {
      channel.unsubscribe(conversationId);
    };
  }, [channel, conversationId]);

  React.useEffect(() => {
    ably.connection.on('connected', () => {
      sendLog(`Ably connection successful : ${channelName}`, true);
    });

    ably.connection.on('disconnected', () => {
      sendLog('Ably disconnected.', true);
    });

    ably.connection.on((stateChange) => {
      sendLog('Ably connection state is ' + stateChange.current, true);
    });

    return () => {
      channel.unsubscribe();
    };
  }, [channel, ably, channelName]);

  return [channel, ably];
};
