import { omitBy } from 'lodash';
import { isResourceInUserRootScope } from '@cpce/console-web-utilities';

export const sanitizeQueryString = (queryString, removeIdIfNoContainer) => {
  let updatedQueryString = omitBy(queryString, (value) => !value);

  if (
    removeIdIfNoContainer &&
    !isResourceInUserRootScope({
      resourceContainer: queryString.containerUri,
      partnerAccountId: queryString.partnerAccountId,
    })
  ) {
    updatedQueryString = omitBy(updatedQueryString, (_, key) => key === 'partnerAccountId');
  }

  return updatedQueryString;
};
