import { chunk } from 'lodash';

/**
 * Extracts resource uri to an object
 * @param  {string} uri
 * @returns {object} empty object if uri is invalid.
 */
const extractResourceUri = (uri) => {
  const matcher = new RegExp('^(/[a-zA-Z0-9_.-]+)+');
  return uri && typeof uri === 'string' && uri.match(matcher)
    ? Object.fromEntries(chunk(uri.split('/').slice(1), 2).filter(([key]) => Boolean(key)))
    : {};
};

export { extractResourceUri };
