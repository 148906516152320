import { HTTP_METHODS } from '@cpce/console-web-api-module';
import { RESOURCE_TYPE_PATH, API_TYPE_ENDPOINTS } from '../constants';
import { apiRequest, processApiResponse, generateUrlBasedOnConfigKey } from '../helpers/helpers';

const generateResourceManagementUrl = (templateUrlPath, urlPathList, queryStringList) =>
  generateUrlBasedOnConfigKey(API_TYPE_ENDPOINTS.RESOURCE_MANAGEMENT_ENDPOINT, templateUrlPath, urlPathList, queryStringList);

export async function createResource({ partnerAccountId, resourceType, containerUri, payload }) {
  const url = generateResourceManagementUrl(RESOURCE_TYPE_PATH, { resourceType });
  const body = {
    payload,
    options: {
      containerUri,
      partnerAccountId,
    },
  };
  return processApiResponse(await apiRequest({ url, method: HTTP_METHODS.POST, payload: body }));
}
