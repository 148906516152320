import React from 'react';
import { selectUi } from '@cpce/console-web-store';
import { onPartnerSwitchConfirmationChanged } from '..';

export const usePartnerSwitchConfirmation = ({ useState, useEffect } = React) => {
  const [partnerSwitchConfirmation, setPartnerSwitchConfirmation] = useState({ show: false, partner: '' });

  const setData = (updatedData) => {
    setPartnerSwitchConfirmation({
      show: updatedData.confirmPartnerSwitch ? true : false,
      partner: updatedData.confirmPartnerSwitch,
    });
  };

  useEffect(() => {
    const unsubscribe = onPartnerSwitchConfirmationChanged(setData, selectUi);

    return () => {
      unsubscribe();
    };
  }, []);

  return partnerSwitchConfirmation;
};
