import { apiRequestDefaultHeaders, HTTP_METHODS } from '@cpce/console-web-api-module';
import get from 'lodash/get';

const BACK_END_FRONT_END_LOGGING_URL = '/api/v1/logging/events';

export const LogLevel = Object.freeze({
  Info: 'INFO',
  Warn: 'WARN',
  Error: 'ERROR',
});

export async function sendLog(message, createBrowserLog = false, logLevel = LogLevel.Info, logToBackendService = false) {
  createBrowserLog && console?.info(`sendLog: ${message}`);

  if (logToBackendService) {
    const body = JSON.stringify({
      message,
      logLevel,
    });

    const options = {
      method: HTTP_METHODS.POST,
      url: BACK_END_FRONT_END_LOGGING_URL,
      payload: body,
      isLoadingUiOn: false,
    };

    const response = await apiRequestDefaultHeaders(options);
    const error = get(response, 'error');
    const data = get(response, 'data');
    const responseData = get(response, 'payload.data');

    if (error) {
      console.warn('sendLog function failed: ', data);
      return;
    }

    return responseData;
  }
}
