import { getConfig } from '@cpce/console-web-config';
import { apiRequestDefaultHeaders } from '@cpce/console-web-api-module';
import configList from '../../config';
import { generateUrl } from './generateUrl';

/**
 * Generate URL based on the configKey, url path template and query string.
 *
 * @param {string} configKey The config key that contains the base path url
 * @param {string} templateUrlPath The url path template string
 * @param {object} [urlPathList={}] The url path object to generate the url path
 * @param {object} [queryStringList={}] The query string object to generate the query string
 * @returns
 */
function generateUrlBasedOnConfigKey(configKey, templateUrlPath, urlPathList, queryStringList) {
  if (configKey && typeof configKey === 'string') {
    const basePathUrl = getConfig(configList)[configKey];

    if (basePathUrl) {
      return generateUrl(basePathUrl, templateUrlPath, urlPathList, queryStringList);
    }
  }
  throw new Error('Missing config key or basePathUrl does not exist in the config');
}

/**
 * Process API response data and return the api response payload data or throw an exception when the error data exists.
 *
 * @param response The response
 * @returns {object} The Payload data for the happy path
 * @throws {Error} The Error object for the sad path
 */
function processApiResponse(response) {
  const {
    error,
    data,
    payload: { data: responseData },
  } = response;

  if (error) {
    throw new Error(data);
  }

  return responseData;
}

export { apiRequestDefaultHeaders as apiRequest, generateUrlBasedOnConfigKey, processApiResponse };
