import { store } from '@cpce/console-web-store';
import { landingPageItemsSelector } from '../helpers';

/**
 * Get the landing page items to be displayed on Dashboard sub-app
 *
 * @export
 * @returns objects in an array that contain data for landing page cards
 */
export default function getLandingPageItems() {
  return landingPageItemsSelector(store.getState());
}
