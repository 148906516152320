import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';

/**
 * Evaluate the provided user permissions and required permissions
 *
 * @export
 * @param {An array of strings} [userPermissions=[]]
 * @param {An array of strings} [requiredPermissions=[]]
 * @returns boolean
 */
export default function evaluatePermissions(userPermissions = [], requiredPermissions = []) {
  if (!requiredPermissions.length) {
    return true;
  }

  const effectivePermissions = intersectionWith(requiredPermissions, userPermissions, isEqual);
  return effectivePermissions.length === requiredPermissions.length;
}
