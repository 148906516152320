import moment from 'moment-timezone';

const DEFAULT_DATE_FORMAT = 'Do MMM YYYY';
const DEFAULT_NO_START_DATE_STRING = '';
const DEFAULT_NO_END_DATE_STRING = 'indefinitely';
const DEFAULT_SEPARATOR = ' - ';
const DEFAULT_TIME_ZONE = 'UTC';

const getLocalTimeZone = () => {
  return moment.tz.guess(true);
};

const getFormattedDateInLocalTimeZone = (dateTime, formatString, timeZone = getLocalTimeZone()) => {
  const dateWithTimezone = moment(moment(dateTime).valueOf()).tz(timeZone || DEFAULT_TIME_ZONE);
  return dateWithTimezone.format(formatString);
};

const getFormattedDateInDefaultTimeZone = (dateTime, formatString) => {
  const dateWithTimezone = moment(moment(dateTime).valueOf()).tz(DEFAULT_TIME_ZONE);
  return dateWithTimezone.format(formatString);
};

const getFormattedDate = (date, format, useLocalTimeZone = false) => {
  const effectiveFormat = format || DEFAULT_DATE_FORMAT;
  return useLocalTimeZone
    ? getFormattedDateInLocalTimeZone(date, effectiveFormat)
    : getFormattedDateInDefaultTimeZone(date, effectiveFormat);
};

const getDate = (date, customFormat, noDateString, useLocalTimeZone) => {
  return date ? getFormattedDate(date, customFormat, useLocalTimeZone) : noDateString;
};

const getDateRange = (startDate, endDate, customFormat, useLocalTimeZone = false, noStartDateString, noEndDateString, separator) => {
  const startDateFormatted = getDate(startDate, customFormat, noStartDateString || DEFAULT_NO_START_DATE_STRING, useLocalTimeZone);
  const endDateFormatted = getDate(endDate, customFormat, noEndDateString || DEFAULT_NO_END_DATE_STRING, useLocalTimeZone);
  return [startDateFormatted, endDateFormatted].join(separator || DEFAULT_SEPARATOR);
};

export { getDateRange, getFormattedDate };
