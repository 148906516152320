import React from 'react';
import { createFormSchemaState } from '../helpers/FormValidationSchemaHelpers';

export const DEFAULT_REQUIRED_FIELD_MESSAGE = 'This is required field';

export function useFormValidation({ useState, useCallback, useRef } = React, data, validationSchema, onSubmitCb) {
  const [formState, setFormState] = useState(createFormSchemaState(data, validationSchema));
  const validityFields = useRef(new Set());

  const isFieldValid = useCallback(
    (name, value) => {
      let error = '';
      if (validationSchema[name].required && !value) {
        error = DEFAULT_REQUIRED_FIELD_MESSAGE;
      }

      if (validationSchema[name].validator !== null && typeof validationSchema[name].validator === 'object') {
        if (value && !validationSchema[name].validator.regEx.test(value)) {
          error = validationSchema[name].validator.error;
        }
      }

      setFormState((prevState) => ({
        ...prevState,
        [name]: { value, error },
      }));

      return error === '';
    },
    [validationSchema]
  );

  const isFormStateValid = useCallback(() => {
    validityFields.current.clear();
    Object.keys(validationSchema).forEach((key) => {
      const isInputFieldRequired = validationSchema[key].required;

      if (isInputFieldRequired) {
        const stateValue = formState[key].value;
        validityFields.current.add(isFieldValid(key, stateValue));
      }
    });

    return !validityFields.current.has(false);
  }, [formState, validationSchema, isFieldValid]);

  const handleOnChange = ({ name, value }) => {
    isFieldValid(name, value);
  };

  const handleOnSubmit = useCallback(
    (event) => {
      event && event.preventDefault();

      if (isFormStateValid()) {
        onSubmitCb(formState);
      }
    },
    [onSubmitCb, formState, isFormStateValid]
  );

  return { formState, handleOnChange, handleOnSubmit };
}
