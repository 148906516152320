import * as React from 'react';
import { store, observeDataChanges, selectFeatureToggles } from '@cpce/console-web-store';

export function useFeatureToggles({ useState, useEffect, useRef } = React) {
  const isMounted = useRef(true);
  const [featureTogglesList, setFeatureTogglesList] = useState({});

  const updateFeatureTogglesList = ({ toggles }) => {
    if (isMounted.current) {
      setFeatureTogglesList(toggles);
    }
  };

  useEffect(() => {
    const unsubscribe = observeDataChanges(store, selectFeatureToggles, updateFeatureTogglesList);

    return () => {
      isMounted.current = false;
      unsubscribe();
    };
  }, []);

  return featureTogglesList;
}
