import { store, selectFlavorUrl } from '@cpce/console-web-store';

/**
 * Returns the current effective app's environment / flavor url
 *
 * @export
 * @returns string - one of a current flavor url for example int.vap.expedia.com for int environment
 */
export default function getFlavorUrl() {
  return selectFlavorUrl(store.getState()) || '';
}
