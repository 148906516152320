import {
  store,
  setSelectedPartner as setSelectedPartnerAction,
  resetSelectedPartner as resetSelectedPartnerAction,
} from '@cpce/console-web-store';

export const setSelectedPartner = (partner) => {
  store.dispatch(setSelectedPartnerAction(partner));
};

export const resetSelectedPartner = () => {
  store.dispatch(resetSelectedPartnerAction());
};
