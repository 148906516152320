import * as React from 'react';

import { DialogVariants } from '@ferris/react/components/dialog/Dialog';

const ACTIONS = Object.freeze({
  TOGGLE_DIALOG: 'TOGGLE_DIALOG',
  DIALOG_CONFIRM: 'DIALOG_CONFIRM',
  DIALOG_REQUEST_BACK: 'DIALOG_REQUEST_BACK',
  RESET: 'RESET',
  DIALOG_CUSTOM_ACTION: 'DIALOG_CUSTOM_ACTION',
  DIALOG_BUILT_IN_CONFIRM: 'DIALOG_BUILT_IN_CONFIRM',
});

const initialState = {
  stepId: '1',
  isDialogVisible: false,
  currentItem: null,
  previousItem: null,
  type: null,
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.TOGGLE_DIALOG: {
      return { ...state, isDialogVisible: payload };
    }
    case ACTIONS.DIALOG_CONFIRM: {
      const { item, type, saveToPreviousItem, nextStepId, requestBackToStepId, closeAfterConfirm } = payload;
      return {
        ...state,
        ...(saveToPreviousItem ? { previousItem: { ...item } } : {}),
        ...(!closeAfterConfirm && nextStepId ? { stepId: nextStepId } : {}),
        ...(requestBackToStepId ? { stepId: requestBackToStepId } : {}),
        ...(closeAfterConfirm ? { isDialogVisible: false, currentItem: null } : { currentItem: item }),
        type,
      };
    }
    case ACTIONS.DIALOG_BUILT_IN_CONFIRM: {
      const { closeAfterConfirm } = payload;
      return {
        ...state,
        ...(closeAfterConfirm ? { isDialogVisible: false } : {}),
      };
    }
    case ACTIONS.DIALOG_REQUEST_BACK: {
      return isNaN(payload)
        ? {
            ...state,
            ...(payload?.requestBackToStepId ? { stepId: payload.requestBackToStepId } : {}),
            currentItem: { ...payload?.item },
            isNotificationBarVisible: false,
          }
        : {
            ...state,
            ...(payload ? { stepId: payload } : {}),
            isNotificationBarVisible: false,
          };
    }
    case ACTIONS.RESET: {
      return { ...initialState };
    }
    case ACTIONS.DIALOG_CUSTOM_ACTION: {
      const { item, type, saveToPreviousItem, nextStepId } = payload;
      return {
        ...state,
        ...(saveToPreviousItem ? { previousItem: { ...item } } : {}),
        ...(nextStepId ? { stepId: nextStepId } : {}),
        type,
        currentItem: { ...item },
        isNotificationBarVisible: false,
      };
    }
  }
};

export const useDialog = ({ mappedUseCaseSteps, confirmCallbackFn, closeCallbackFn, DialogImplementation }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const { stepId, currentItem, isDialogVisible } = state;
  const {
    title,
    closeAfterConfirm = false,
    contentComponentProps = {},
    ContentComponent = () => <>Default Content Component</>,
    dialogVariant = DialogVariants.PANEL,
    nextStepId,
    requestBackToStepId,
    confirmationButtonText,
    discardButtonText,
    classes,
    isMessageNodeDialog = false,
    saveItemOnBack = false,
  } = mappedUseCaseSteps?.find((step) => step.id === stepId) || {};

  const { item } = contentComponentProps;

  const handleOpenDialog = React.useCallback(() => {
    dispatch({ type: ACTIONS.TOGGLE_DIALOG, payload: true });
  }, []);

  const handleCloseDialog = React.useCallback(() => {
    dispatch({ type: ACTIONS.RESET });
    closeCallbackFn?.();
  }, [closeCallbackFn]);

  const handleDialogConfirm = React.useCallback(
    ({ item, type, saveToPreviousItem }) => {
      dispatch({
        type: ACTIONS.DIALOG_CONFIRM,
        payload: { item, type, saveToPreviousItem, nextStepId, requestBackToStepId, closeAfterConfirm },
      });
      confirmCallbackFn?.({ item, type });
    },
    [confirmCallbackFn, closeAfterConfirm, nextStepId, requestBackToStepId]
  );

  const handleBuiltInDialogConfirm = React.useCallback(() => {
    const { actionType } = item;
    confirmCallbackFn?.({ item, type: actionType });
    dispatch({
      type: ACTIONS.DIALOG_BUILT_IN_CONFIRM,
      payload: { closeAfterConfirm },
    });
  }, [closeAfterConfirm, confirmCallbackFn, item]);

  const handleDialogRequestBack = React.useCallback(
    saveItemOnBack
      ? ({ item }) => {
          dispatch({ type: ACTIONS.DIALOG_REQUEST_BACK, payload: { item, requestBackToStepId } });
        }
      : () => {
          dispatch({ type: ACTIONS.DIALOG_REQUEST_BACK, payload: requestBackToStepId });
        },
    [requestBackToStepId, saveItemOnBack]
  );

  const handleCustomAction = React.useCallback(
    ({ item, type, saveToPreviousItem }) => {
      dispatch({ type: ACTIONS.DIALOG_CUSTOM_ACTION, payload: { item, type, saveToPreviousItem, nextStepId } });
    },
    [nextStepId]
  );

  const DialogContainer = ({ children }) => {
    return (
      <DialogImplementation
        title={title || item?.title || 'Default Title'}
        isOpen={isDialogVisible}
        onCloseCancel={handleCloseDialog}
        variant={dialogVariant}
        multiStep={!!requestBackToStepId}
        onRequestBack={handleDialogRequestBack}
        confirmationButtonText={confirmationButtonText}
        discardButtonText={discardButtonText}
        onCloseConfirm={handleBuiltInDialogConfirm}
        classes={classes}
        isMessageNodeDialog={isMessageNodeDialog}
      >
        {children}
        <ContentComponent
          {...contentComponentProps}
          item={currentItem || item}
          onCustomAction={handleCustomAction}
          onConfirmSelection={handleDialogConfirm}
          onCloseAction={handleCloseDialog}
          onBackAction={handleDialogRequestBack}
        />
      </DialogImplementation>
    );
  };

  return {
    DialogContainer: ({ children }) => <DialogContainer>{children}</DialogContainer>,
    close: handleCloseDialog,
    open: handleOpenDialog,
  };
};
