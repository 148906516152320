import { store, setSubApps, setFlavor, setFlavorUrl } from '@cpce/console-web-store';
import { HTTP_METHODS } from '@cpce/console-web-api-module';
import { apiRequestDefaultHeaders } from '@cpce/console-web-api-module';

export async function getAppData(configData) {
  const { APP_DATA_ENDPOINT } = configData;
  const url = APP_DATA_ENDPOINT,
    payload = {};

  // Note: This dispatch thunk call doesn't need to implement `try catch` since it's been handled by the thunk function that would
  // return error when it catches the exception
  const response = await apiRequestDefaultHeaders({
    url,
    payload,
    method: HTTP_METHODS.GET,
    customStatusMessage: {
      rejectedMessage: 'Unable to fetch app data. Please try again',
    },
    loadingScreenMessage: 'Fetching App Data.',
    additionalHeaders: {
      'x-bypass-cgp': true,
    },
  });

  const {
    error,
    data,
    payload: { data: responseData },
  } = response;
  if (error) {
    throw new Error(data);
  }

  const { flavor, flavorUrl, subAppConfigurations } = responseData;
  const shrinkedSubAppsData = shrinkSubAppsData(subAppConfigurations);

  store.dispatch(setSubApps(shrinkedSubAppsData));
  store.dispatch(setFlavor(flavor));
  store.dispatch(setFlavorUrl(flavorUrl));
  return responseData;
}

export const shrinkSubAppsData = (subApps) => {
  return subApps.map(({ enabled, versions, ...subAppData }) => subAppData);
};
