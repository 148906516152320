import URI from 'urijs';
import URITemplate from 'urijs/src/URITemplate';

/**
 * Generate URL based on the url path template and query string
 *
 * @param {string} templateUrlPath The url path template string
 * @param {object} [urlPathList={}] The url path object to generate the url path
 * @param {object} [queryStringList={}] The query string object to generate the query string
 * @returns
 */
export function generateUrl(baseUrl = '', templateUrlPath, urlPathList = {}, queryStringList = {}) {
  const urlPath = new URITemplate(templateUrlPath).expand({ ...urlPathList });
  const uri = new URI(`${baseUrl}${urlPath}`);

  for (const queryStringItem in queryStringList) {
    uri.addQuery({ [queryStringItem]: queryStringList[queryStringItem] });
  }

  return uri.href();
}
