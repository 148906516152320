import { createSlice } from '@reduxjs/toolkit';
import { makeApiRequestThunk } from './makeApiRequestThunk';

const API_SLICE_NAME = 'api';

const initialState = {
  inProgress: 0,
  error: undefined,
};

const reducers = {
  apiRequestInProgress: (state) => {
    state.inProgress = 1;
    state.error = undefined;
  },
  apiRequestFulfilled: (state) => {
    state.inProgress = 0;
  },
  apiRequestInProgressCounter: (state) => {
    state.inProgress += 1;
    state.error = undefined;
  },
  apiRequestFulfilledCounter: (state) => {
    if (state.inProgress > 0) state.inProgress -= 1;
  },
  resetApiProgressCounter: (state) => {
    state.inProgress = 0;
  }
};

// ATTN: 'extraReducers' is a naming convention specific to createAsyncThunk which is why it isn't grouped with other reducers.
const extraReducers = {
  [makeApiRequestThunk.rejected]: (state, { payload }) => {
    state.inProgress = 0;
    state.error = payload;
  },
};

const apiSlice = createSlice({
  name: API_SLICE_NAME,
  initialState,
  extraReducers,
  reducers,
});

export const apiReducer = apiSlice.reducer;
export const selectApi = (state) => state.api;

export const { apiRequestInProgress, apiRequestFulfilled, apiRequestInProgressCounter, apiRequestFulfilledCounter, resetApiProgressCounter } = apiSlice.actions;
