import * as React from 'react';

export const getBrandConfiguration = ({ icons = {}, nameMapping = {} } = {}) => {
  function getIcon(name) {
    const iconName = nameMapping[name] || name;

    // The icons data comes back as an HTML string. React has security safeguards to protect
    // against XSS attacks and sanitizes HTML strings if you try to just set innerHTML. React provides,
    // an alternative scary looking innerHTML replacement called `dangerouslySetInnerHTML`. When you
    // control where the HTML string comes from and know it's safe, this API provides the backdoor
    // to go ahead and use it. https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
    // Be sure to read:https://overreacted.io/why-do-react-elements-have-typeof-property/
    // More:
    //    https://betterprogramming.pub/what-is-dangerouslysetinnerhtml-6d6a98cbc187
    //    https://www.pluralsight.com/guides/how-to-render-html-in-state-string
    // Opting to use the dangerouslySetInnerHTML prop in lieu of a library.
    // For example: html-react-parser@1.2.4 is 7.6kb gzipped

    const svgPathInnerHtmlString = icons[iconName]?.data;

    function egdsBrandIconSvg(props) {
      const innerSvgProps = {
        dangerouslySetInnerHTML: {
          __html: svgPathInnerHtmlString,
        },
        ...props,
      };

      return <svg {...innerSvgProps} />;
    }

    return svgPathInnerHtmlString ? egdsBrandIconSvg : null;
  }

  function getIconPath(name) {
    const iconName = nameMapping[name] || name;
    return icons[iconName]?.data;
  }

  return {
    getIcon,
    getIconPath,
  };
};
