/**
 * Determines whether or not a resource belongs to a partner
 * @param  {string|null} resourceContainer This is a resource container/partial URI
 * @param  {string|null} partnerAccountId The unique guid assigned to a partner
 * @returns {boolean} Indicates whether or not the resource is editable
 */
export const isResourceInUserRootScope = ({ resourceContainer, partnerAccountId }) => {
  if (!resourceContainer && !partnerAccountId) return true;
  if (resourceContainer?.includes(partnerAccountId)) return true;
  return false;
};
