import { createSelector } from '@reduxjs/toolkit';
import { selectSubApps, selectUser, selectFeatureToggles } from '@cpce/console-web-store';
import { evaluateAuthorizedIf } from '../../../helpers';

const allNavigationItemsSelector = (state) => {
  return selectSubApps(state).map(({ navigation: { title, parentCategoryId, links, alwaysShowAsDropdown }, isEgIdentitySupported }) => ({
    title,
    links,
    alwaysShowAsDropdown,
    parentCategoryId,
    isEgIdentitySupported,
  }));
};

const navigationItemsSelector = createSelector(
  allNavigationItemsSelector,
  selectUser,
  selectFeatureToggles,
  (allNavigationItems, user, featureToggles) => {
    const {
      activePartner: { profile, permissions: userPermissions },
      isEGLoginSession,
    } = user;
    const { partnerType: userPartnerType } = profile;
    const authorizedNavigationItems = allNavigationItems.map((navigationItem) => {
      navigationItem.links = navigationItem.links.filter(({ authorizedIf }) => {
        const isAuthorized = evaluateAuthorizedIf({ authorizedIf, userPermissions, userPartnerType });
        return featureToggles?.toggles?.isEgIdentityToggleEnabledInAppManager && isEGLoginSession
          ? navigationItem.isEgIdentitySupported && isAuthorized
          : isAuthorized;
      });
      return navigationItem;
    });
    
    return authorizedNavigationItems.filter((authorizedNavigationItem) => Boolean(authorizedNavigationItem.links.length));
  }
);

export default navigationItemsSelector;
