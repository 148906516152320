import React from 'react';
import { observeDataChanges, selectUserPreferences, store } from '@cpce/console-web-store';

export const useUserPreferences = ({ useState, useEffect } = React, appId) => {
  const initialState = {
    isLoaded: false,
    globalPreferences: {},
    visitedSubAppPreferences: {},
  };

  const [userPreferencesProps, setUserPreferencesProps] = useState(initialState);

  useEffect(() => {
    const unsubscribe = observeDataChanges(store, selectUserPreferences, (newUserPreferences) =>
      setUserPreferencesProps(newUserPreferences)
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const { isLoaded, globalPreferences: globalPreferencesFromProps, visitedSubAppPreferences } = userPreferencesProps;

  const globalPreferences = globalPreferencesFromProps?.preferences || {};
  const subAppPreferences = (visitedSubAppPreferences && visitedSubAppPreferences[appId]?.preferences) || {};
  return { isLoaded, globalPreferences, subAppPreferences };
};
