import { createSelector } from '@reduxjs/toolkit';

import { selectSubApps, selectUser, selectFeatureToggles } from '@cpce/console-web-store';
import { evaluateAuthorizedIf } from '../../../helpers';

const allLandingPageItemsSelector = (state) => {
  return selectSubApps(state).reduce((acc, { landingPageItems, isEgIdentitySupported }) => {
    const mappedLandingPageItems = landingPageItems.map((item) => ({ ...item, isEgIdentitySupported }));
    acc.push(...mappedLandingPageItems);
    return acc;
  }, []);
};

const landingPageItemsSelector = createSelector(
  allLandingPageItemsSelector,
  selectUser,
  selectFeatureToggles,
  (allLandingPageItem, user, featureToggles) => {
    const {
      activePartner: { profile, permissions: userPermissions },
      isEGLoginSession,
    } = user;
    const { partnerType: userPartnerType } = profile;
    return allLandingPageItem.filter(({ authorizedIf, isEgIdentitySupported }) => {
      const isAuthorized = evaluateAuthorizedIf({ authorizedIf, userPermissions, userPartnerType });
      return featureToggles?.toggles?.isEgIdentityToggleEnabledInAppManager && isEGLoginSession
        ? isEgIdentitySupported && isAuthorized
        : isAuthorized;
    });
  }
);

export default landingPageItemsSelector;
