import merge from 'lodash.merge';
import isplainobject from 'lodash.isplainobject';

import { getFlavor } from '@cpce/console-web-app-module';

const BASE_CONFIG_KEY = 'all';

export default function getConfig(configList, overrideConfig = {}) {
  const flavor = getFlavor();
  return configList && isplainobject(configList) ? merge({}, configList[BASE_CONFIG_KEY], configList[flavor], overrideConfig) : {};
}
