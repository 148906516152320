import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { reduxBatch } from '@manaflair/redux-batch';

import rootReducer from './rootReducer';
import { setupStoreWithPersistor } from './persistor';

const defaultConfigStoreArg = {
  middleware: getDefaultMiddleware,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch],
};

export const store =
  process.env.NODE_ENV === 'test'
    ? configureStore({
        ...defaultConfigStoreArg,
        reducer: rootReducer,
      })
    : setupStoreWithPersistor(rootReducer);
