import * as React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import URI from 'urijs';

import { setAppClientId, AUTH_IDENTITY_FLOWS, exchangeAuthCode, getLoggedInUser } from '../../auth-module/authModule';
import useAuth from '../useAuth/useAuth';

const EG_LOGIN_URL = 'EG_LOGIN_URL';
const BACK_END_EG_IDENTITY_AUTH_FLOW_ENDPOINTS = 'BACK_END_EG_IDENTITY_AUTH_FLOW_ENDPOINTS';
const REDIRECT_URI_KEY = 'redirect_uri';
const CLIENT_ID_KEY = 'client_id';

function generateLoginUrl({ baseUrl, urlPath, redirectUrlPath, queryStrings }, appBaseUrl) {
  const redirectUri = URI(appBaseUrl).pathname(redirectUrlPath);

  const redirectUrl = redirectUri.href();
  queryStrings[REDIRECT_URI_KEY] = redirectUrl;

  const uri = URI(baseUrl)
    .pathname(urlPath)
    .search({ ...queryStrings });

  return { redirectUrl, loginUrl: URI.decode(uri.href()) };
}

function getClientId({ queryStrings }) {
  return queryStrings[CLIENT_ID_KEY];
}

function generateApiBackendUrl({ baseUrl, path }) {
  return URI(baseUrl).pathname(path).href();
}

function getApiBackendEndpoint(appBaseUrl, backendUrlsConfig) {
  return Object.entries(backendUrlsConfig).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: generateApiBackendUrl({ baseUrl: appBaseUrl, path: value }) }),
    {}
  );
}

export default function useEGIdentityLoginFlow(configData, appBaseUrl, sendLogFn, queryStrings = {}) {
  const { useCallback, useMemo, useState, useEffect } = React;

  const [isDone, setIsDone] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const loginUrlConfig = configData[EG_LOGIN_URL];
  const backendUrlsConfig = configData[BACK_END_EG_IDENTITY_AUTH_FLOW_ENDPOINTS];
  const apiBackendUrls = useMemo(() => {
    return getApiBackendEndpoint(appBaseUrl, backendUrlsConfig);
  }, [appBaseUrl, backendUrlsConfig]);
  const { redirectUrl, loginUrl } = useMemo(() => {
    return generateLoginUrl(loginUrlConfig, appBaseUrl);
  }, [appBaseUrl, loginUrlConfig]);
  const clientId = useMemo(() => {
    return getClientId(loginUrlConfig);
  }, [loginUrlConfig]);

  const { setAuthFlowType, authFlow } = useAuth(React);

  const { code, error } = queryStrings;

  const initiateLogin = useCallback(() => {
    setAppClientId(clientId);
    setAuthFlowType(AUTH_IDENTITY_FLOWS.EG_IDENTITY);
    sendLogFn?.(`EG login flow - URL: ${loginUrl}`, true);
    window.location.replace(loginUrl);
  }, [clientId, loginUrl, sendLogFn, setAuthFlowType]);

  useEffect(() => {
    if (isDone || isFailed) {
      window.location.replace(appBaseUrl);
    }
  }, [isDone, isFailed]);

  useEffect(() => {
    if (error) {
      sendLogFn(`Getting redirect error response - Error: ${error}`, true);
      window.location.replace(appBaseUrl);
    }
  }, [error]);

  useDeepCompareEffect(() => {
    (async (authCode) => {
      if (authCode && authFlow === AUTH_IDENTITY_FLOWS.EG_IDENTITY) {
        const { EXCHANGE_AUTH_CODE_ENDPOINT, GET_USER_DATA_ENDPOINT } = apiBackendUrls;
        sendLogFn(`Executing EG login flow - auth code ${authCode}`, true);
        try {
          await exchangeAuthCode(EXCHANGE_AUTH_CODE_ENDPOINT, { authCode, redirectUrl });
          await getLoggedInUser(GET_USER_DATA_ENDPOINT);
          setIsDone(true);
        } catch (error) {
          sendLogFn?.(`Failed on EG login flow - Error: ${JSON.stringify(error)}`, true);
          setIsFailed(true);
        }
      }
    })(code);
  }, [apiBackendUrls, code, redirectUrl]);

  return { initiateLogin, isDone, isFailed };
}
