import { store, setRolePreferences, setIsRolePreferencesLoaded } from '@cpce/console-web-store';
import pick from 'lodash/pick';
import { HTTP_METHODS } from '@cpce/console-web-core-api';
import { apiRequestDefaultHeaders, generateUrl, processApiResponse, ROLE_PREFERENCES_PATH } from '../helpers/helpers';

export const loadRolePreferences = async (roleUri, federatedSessionRolePreferences) => {
  store.dispatch(setIsRolePreferencesLoaded({ isLoaded: false }));

  if (federatedSessionRolePreferences) {
    const newRolePreferences = {
      isLoaded: true,
      rolePreferences: { preferences: federatedSessionRolePreferences },
    };

    store.dispatch(setRolePreferences(newRolePreferences));
  } else {
    const rolePreferencesUrl = generateUrl(ROLE_PREFERENCES_PATH, { roleUri });

    const response = await apiRequestDefaultHeaders({
      url: rolePreferencesUrl,
      isLoadingUiOn: false,
      method: HTTP_METHODS.GET,
    });

    const rolePreferencesResponse = processApiResponse(response);

    const newRolePreferences = {
      isLoaded: true,
      rolePreferences: { ...pick(rolePreferencesResponse || {}, ['id', 'altId', 'preferences']) },
    };

    store.dispatch(setRolePreferences(newRolePreferences));
  }
};
