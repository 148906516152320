const IntegerMaxLimit = 2147483648;
const IsIntegerRegex = new RegExp('^(0|[1-9]\\d*)$');
const _ = require('lodash');

export const convertObjectToArrayOfObjects = (obj) => {
  return obj ? Object.keys(obj).map((key) => ({ key, value: obj[key] })) : [];
};

export const defaultToEmptyString = (data) => _.defaultTo(data, '');

export const validateNumericInput = (input) => {
  return !input || (IsIntegerRegex.test(input) && input >= 0 && input <= IntegerMaxLimit);
};

export const convertArrayOfObjectsToObject = (arr) => {
  return arr?.reduce((obj, { key, value }) => ({ ...obj, [key]: value }), {});
};
