import { createSlice } from '@reduxjs/toolkit';

const TOKEN_SLICE_NAME = 'token';

const initialState = {
  idToken: '',
  refreshToken: '',
  isValid: false,
  exp: 0,
  lastReceived: 0,
  opaqueTokenExp: 0,
};

const reducers = {
  receiveTokens: (state, { payload: { idToken, refreshToken, tokenExp, tokenIssuedAt, opaqueTokenExp } }) => {
    state.idToken = idToken;
    state.refreshToken = refreshToken;
    state.isValid = true;
    state.exp = tokenExp;
    state.lastReceived = tokenIssuedAt;
    state.opaqueTokenExp = opaqueTokenExp;
  },
  resetToken: () => {
    return initialState;
  },
};

const tokenSlice = createSlice({
  name: TOKEN_SLICE_NAME,
  initialState,
  reducers,
});

const { receiveTokens, resetToken } = tokenSlice.actions;
const selectToken = (state) => state.token;
const tokenReducer = tokenSlice.reducer;

export { receiveTokens, resetToken, selectToken, tokenReducer };
