import { selectUi, selectApi, selectField } from '@cpce/console-web-store';

const selectUiStateField = (state, fieldName) => selectField(state, selectUi, fieldName);
const selectApiStateField = (state, fieldName) => selectField(state, selectApi, fieldName);

const defaultShowMessageData = Object.freeze({
  message: 'This is default message',
  bannerType: 'info',
  autoHideDuration: 5000,
});

const parseJSONOrReturnFalse = (jsonString) => {
  let parsedFile;
  try {
    parsedFile = JSON.parse(jsonString);
  } catch (e) {
    return false;
  }
  return parsedFile;
};

export { selectUiStateField, selectApiStateField, defaultShowMessageData, parseJSONOrReturnFalse };
