const CCP_INTERNAL = 'CCP Internal';

const apiFriendlyPartnerId = (user, idToNullify) => {
  const id = user?.activePartner?.profile?.partnerId;
  const invalidId = idToNullify ? idToNullify : CCP_INTERNAL;
  if (id === invalidId) {
    return null;
  }
  return id;
};

export { apiFriendlyPartnerId };
