import { store, setUserPreferences as storeUserPreferences } from '@cpce/console-web-store';
import {
  apiRequestDefaultHeaders,
  generateUrl,
  getUserPreferencesState,
  processApiResponse,
  USER_PREFERENCES_PATH,
  PLATFORM,
} from '../helpers/helpers';

export const setUserPreferences = async (userResourceUri, payload, appId = PLATFORM, partnerAccountId, userId, isFederatedSession) => {
  const toInvokeApiCall = isFederatedSession ? !!(partnerAccountId && userId) : !!userResourceUri;
  if (!toInvokeApiCall) {
    return;
  }

  const { globalPreferences: currGlobalPreferences, visitedSubAppPreferences: currVisitedSubAppPreferences } = getUserPreferencesState();

  const url = generateUrl(USER_PREFERENCES_PATH, {
    userResourceUri,
    appId,
  });

  const { id, altId, preferences = {} } = processApiResponse(
    await apiRequestDefaultHeaders({
      url,
      method: 'PUT',
      payload: {
        payload,
        partnerAccountId,
        userId,
      },
    })
  );

  let newGlobalPreferences, newVisitedSubAppPreferences;
  if (appId === PLATFORM) {
    newGlobalPreferences = { ...currGlobalPreferences, id, appId, altId, preferences };
    newVisitedSubAppPreferences = { ...currVisitedSubAppPreferences };
  } else {
    newGlobalPreferences = { ...currGlobalPreferences };
    newVisitedSubAppPreferences = { ...currVisitedSubAppPreferences };
    newVisitedSubAppPreferences[appId] = { ...currVisitedSubAppPreferences[appId], id, altId, appId, preferences };
  }

  store.dispatch(
    storeUserPreferences({
      isLoaded: true,
      globalPreferences: newGlobalPreferences,
      visitedSubAppPreferences: newVisitedSubAppPreferences,
    })
  );
};
