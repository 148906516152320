import { evaluatePartnerType, evaluatePermissions } from '../';

/**
 * Evaluate AuthorizedIf object against the current user permissions and partner type
 *
 * @export
 * @param {object} { authorizedIf: { partnerTypes, permissions }, userPermissions, userPartnerType}
 * @returns bool
 */
export default function evaluateAuthorizedIf({ authorizedIf: { partnerTypes, permissions }, userPermissions, userPartnerType }) {
  return evaluatePartnerType(userPartnerType, partnerTypes) && evaluatePermissions(userPermissions, permissions);
}
