export const getSearchValueRegex = (searchValue) => {
  let searchValueRegex = '';
  // The regex /\W+/g will eliminate special characters
  // If user pass special characters directly to regex without escaping, it will break the code.
  const searchValueArr = searchValue ? searchValue.replace(/\W+/g, ' ').split(' ') : [];
  searchValueArr
    .filter((word) => Boolean(word))
    .forEach((word) => {
      if (searchValueRegex.length) {
        searchValueRegex += '|';
      }
      searchValueRegex += `(${word.toLowerCase()})`;
    });
  return searchValueRegex;
};
