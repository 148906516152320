import React from 'react';
import { selectUser } from '@cpce/console-web-store';

import { onUserChanged } from '..';

// ATTN: while creating default values for hooks, consider not simply setting them to the default/initial values from their corresponding data source (in the case, the user slice from redux).
// This gives us more fine-tuned control into how the rendering should work based on our app state. Example can be seen in apps/root-app/src/Root.js within the useEffect hook.
export const useUser = ({ useState, useEffect } = React) => {
  const [user, setUser] = useState({
    userId: '',
    userName: '',
    isLoggedIn: undefined,
    isAuthorized: undefined,
    role: '',
    profile: {},
    permissions: [],
    activePartner: {
      profile: {},
      permissions: [],
    },
    authorizedRoutes: [],
    hasCPaaSManagementPermission: undefined,
  });

  const setData = (updatedData) => {
    setUser({
      ...updatedData,
    });
  };

  useEffect(() => {
    const unsubscribe = onUserChanged(setData, selectUser);

    return () => {
      unsubscribe();
    };
  }, []);

  return user;
};
