import { apiRequestDefaultHeaders } from '@cpce/console-web-api-module';

const getSubAppConfigEndpoint = (subAppId) => `/api/v1/appdata/${subAppId}/configs`;

const mapConfigResponse = (response) => {
  const { config } = response;

  const configObj = config.reduce((result, item) => {
    result[item.key] = item.value;
    return result;
  }, {});

  return configObj;
};

export const getRemoteConfig = async (subAppId) => {
  const url = getSubAppConfigEndpoint(subAppId);

  try {
    const response = await apiRequestDefaultHeaders({
      url,
      customStatusMessage: { showError: false },
    });

    const {
      error,
      payload: { data: responseData },
    } = response;

    if (error) {
      throw new Error(responseData);
    }

    return mapConfigResponse(responseData);
  } catch (_responseData) {
    return {};
  }
};
