import { createAsyncThunk } from '@reduxjs/toolkit';
import { coreApiRequest, HTTP_METHODS } from '@cpce/console-web-core-api';

import { processResponse, processErrorResponse, processPreRequest, processPostRequest } from './helpers/helpers';

export const makeApiRequestThunk = createAsyncThunk('api/makeApiRequest', async (options, { rejectWithValue }) => {
  const {
    store,
    apiRequestOptions: { method: httpMethod = HTTP_METHODS.GET },
    loadingScreenMessage = '',
    customStatusMessage: {
      bannerType = '',
      bannerNote = '',
      fulfilledMessage = '',
      rejectedMessage = '',
      showUnderlyingError = true,
      showError = true,
    } = {},
    isLoadingUiOn,
  } = options;
  const { apiRequestOptions } = options,
    loadingScreenRequestOptions = { store, loadingScreenMessage, isLoadingUiOn };
  try {
    processPreRequest(loadingScreenRequestOptions);
    const resp = await coreApiRequest(apiRequestOptions);
    processResponse(store, fulfilledMessage, bannerNote, bannerType, httpMethod, isLoadingUiOn);
    return resp;
  } catch (error) {
    const {
      response: { status, statusText, data },
      message,
      config,
    } = error;
    processErrorResponse(
      store,
      error,
      rejectedMessage,
      bannerNote,
      bannerType,
      showUnderlyingError,
      showError,
      apiRequestOptions,
      config.headers
    );
    return rejectWithValue({ status, statusText, data, message });
  } finally {
    processPostRequest(loadingScreenRequestOptions);
  }
});
