import { store, setUserPreferences, setIsUserPreferencesLoaded } from '@cpce/console-web-store';
import pick from 'lodash/pick';
import { apiRequestDefaultHeaders, generateUrl, getUserPreferencesState, processApiResponse, PLATFORM } from '../helpers/helpers';

export const loadUserPreferences = async (userResourceUri, subApp, partnerAccountId, userId, isFederatedSession) => {
  store.dispatch(setIsUserPreferencesLoaded({ isLoaded: false }));

  const { globalPreferences: currGlobalPreferences, visitedSubAppPreferences: currVisitedSubAppPreferences } = getUserPreferencesState();
  const { id: subAppId, name: appName } = subApp;
  let newGlobalPreferences = { ...currGlobalPreferences };
  let newVisitedSubAppPreferences = { ...currVisitedSubAppPreferences };
  const toInvokeApiCall = isFederatedSession ? !!(partnerAccountId && userId) : !!userResourceUri;

  if (toInvokeApiCall) {
    const appIds = subAppId ? `${PLATFORM}, ${subAppId}` : PLATFORM;
    const globalPreferencesUrl = generateUrl('', { userResourceUri, appIds, partnerAccountId, userId });

    const [[globalPreferencesResponse], [subAppPreferencesResponse]] = processApiResponse(
      await apiRequestDefaultHeaders({
        url: globalPreferencesUrl,
        isLoadingUiOn: false,
      })
    );

    newGlobalPreferences = {
      preferences: {},
      ...pick(globalPreferencesResponse || {}, ['id', 'appId', 'altId', 'preferences']),
    };

    if (subAppId) {
      newVisitedSubAppPreferences[subAppId] = {
        preferences: {},
        ...pick(subAppPreferencesResponse || {}, ['id', 'altId', 'preferences']),
        appId: subAppId,
        appName,
      };
    }
  }

  const newUserPreferences = {
    isLoaded: true,
    globalPreferences: newGlobalPreferences,
    visitedSubAppPreferences: newVisitedSubAppPreferences,
  };

  store.dispatch(setUserPreferences(newUserPreferences));
};
