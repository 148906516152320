import { loadAppWithAuthorization } from './loadAppWithAuthorization';
import singleSpa from 'single-spa';

export async function registerSubApps(appData, configData) {
  const { BOOK_MARK_PATH_KEY } = configData;
  const { registerApplication } = singleSpa;

  appData.subAppConfigurations.forEach((subApp) => {
    if (subApp.routes.length <= 0 || subApp.isWidget) {
      return;
    }

    registerApplication({
      name: subApp.name,
      app: () => loadAppWithAuthorization(subApp, BOOK_MARK_PATH_KEY),
      activeWhen: subApp.routes,
    });
  });
}
