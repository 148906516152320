import React from 'react';
import { observeDataChanges, selectRolePreferences, store } from '@cpce/console-web-store';

export const useRolePreferences = ({ useState, useEffect } = React) => {
  const initialState = {
    isLoaded: true,
    rolePreferences: {},
  };

  const [rolePreferencesProps, setRolePreferencesProps] = useState(initialState);

  useEffect(() => {
    const unsubscribe = observeDataChanges(store, selectRolePreferences, (newRolePreferences) =>
      setRolePreferencesProps(newRolePreferences)
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return rolePreferencesProps;
};
