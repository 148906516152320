import { createSlice } from '@reduxjs/toolkit';

const TRUSTED_PARTNERS_SLICE_NAME = 'trustedPartners';

const initialState = [];

const reducers = {
  loadTrustedPartners: (state, { payload }) => {
    return payload;
  },
  resetTrustedPartners: () => {
    return initialState;
  },
};

const trustedPartnersSlice = createSlice({
  name: TRUSTED_PARTNERS_SLICE_NAME,
  initialState,
  reducers,
});

const { loadTrustedPartners, resetTrustedPartners } = trustedPartnersSlice.actions;
const selectTrustedPartners = (state) => state.trustedPartners;
const trustedPartnersReducer = trustedPartnersSlice.reducer;

export { loadTrustedPartners, resetTrustedPartners, selectTrustedPartners, trustedPartnersReducer };
