import { createSlice } from '@reduxjs/toolkit';

const USER_PREFERENCES_SLICE_NAME = 'userPreferences';

const initialState = {
  isLoaded: false,
  globalPreferences: {},
  visitedSubAppPreferences: {},
};

const reducers = {
  setUserPreferences: (state, { payload }) => {
    const { isLoaded, globalPreferences, visitedSubAppPreferences } = payload;
    state.isLoaded = isLoaded;
    state.globalPreferences = globalPreferences;
    state.visitedSubAppPreferences = visitedSubAppPreferences;
  },
  setIsUserPreferencesLoaded: (state, { payload: { isLoaded } }) => {
    state.isLoaded = isLoaded;
  },
  resetUserPreferences: () => {
    return initialState;
  },
};

const userPreferencesSlice = createSlice({
  name: USER_PREFERENCES_SLICE_NAME,
  initialState,
  reducers,
});

const { setUserPreferences, setIsUserPreferencesLoaded, resetUserPreferences } = userPreferencesSlice.actions;
const selectUserPreferences = (state) => state.userPreferences;
const userPreferencesReducer = userPreferencesSlice.reducer;

export { setUserPreferences, setIsUserPreferencesLoaded, resetUserPreferences, selectUserPreferences, userPreferencesReducer };
