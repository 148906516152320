import axios from 'axios';

/**
 * @typedef {Object} CancelAction
 * @property {Object} cancelToken
 * @property {Function} cancel
 */

/**
 * This function returns the cancel action instance that contains the cancel token and 'cancel' function
 * to cancel the API request after passing the token from this instance to `coreApiRequest` as an option which is `cancelToken` property
 * @see https://github.com/axios/axios#cancellation for the examples of implementation
 * @returns {CancelAction}
 */
export function createCancelAction() {
  return axios.CancelToken.source();
}
