import URITemplate from 'urijs/src/URITemplate';
import URI from 'urijs';
import { apiRequestDefaultHeaders } from '@cpce/console-web-api-module';
import { store, selectUserPreferences } from '@cpce/console-web-store';

const PLATFORM = 'PLATFORM';
const USER_PREFERENCES_ENDPOINT = '/api/v1/userpreferencesconfig';
const USER_PREFERENCES_PATH = '/userpreferences';

const generateUrl = (templateUrlPath, queryStringList = {}) => {
  const urlPath = new URITemplate(templateUrlPath).expand({});
  const uri = new URI(`${USER_PREFERENCES_ENDPOINT}${urlPath}`);

  for (const queryStringItem in queryStringList) {
    uri.addQuery({ [queryStringItem]: queryStringList[queryStringItem] });
  }

  return uri.href();
};

const processApiResponse = (response) => {
  const {
    error,
    data,
    payload: { data: responseData },
  } = response;

  if (error) {
    throw new Error(data);
  }

  return responseData;
};

const getUserPreferencesState = () => selectUserPreferences(store.getState());

export {
  apiRequestDefaultHeaders,
  generateUrl,
  getUserPreferencesState,
  processApiResponse,
  PLATFORM,
  USER_PREFERENCES_ENDPOINT,
  USER_PREFERENCES_PATH,
};
