const DEFAULT_ITEM_SEPARATOR = ', ';
const DEFAULT_EMPTY_VALUE = '-';

const getCombinedStrings = (items, itemSeparator, emptyValue) => {
  return items && items.length
    ? items.join(itemSeparator ? itemSeparator : DEFAULT_ITEM_SEPARATOR)
    : emptyValue
    ? emptyValue
    : DEFAULT_EMPTY_VALUE;
};

export { getCombinedStrings };
