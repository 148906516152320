import { evaluateAuthorizedIf } from '@cpce/console-web-app-module';

const AUTHORIZED_IF_SHELL = {
  authorizedIf: {
    permissions: [],
    partnerTypes: [],
  },
};

export const isAuthorizedApp = (appData, userData) => {
  if (appData) {
    const {
      landingPageItems,
      navigation: { links },
    } = appData;

    if (!landingPageItems.length && !links.length) {
      return true;
    }

    const evaluateFrom = ({ authorizedIf }) => ({
      authorizedIf: authorizedIf || AUTHORIZED_IF_SHELL,
      userPermissions: userData.activePartner.permissions,
      userPartnerType: userData.activePartner.profile?.partnerType,
    });

    if (landingPageItems.some((item) => evaluateAuthorizedIf(evaluateFrom(item)))) {
      return true;
    }

    if (links.some((link) => evaluateAuthorizedIf(evaluateFrom(link)))) {
      return true;
    }
  }

  return false;
};
