import { createSlice } from '@reduxjs/toolkit';

const APP_SLICED_NAME = 'app';

const initialState = {
  flavor: '',
  flavorUrl: '',
  subApps: [],
};

const reducers = {
  setFlavor: (state, { payload }) => {
    state.flavor = payload;
  },
  setFlavorUrl: (state, { payload }) => {
    state.flavorUrl = payload;
  },
  setSubApps: (state, { payload }) => {
    state.subApps = payload;
  },
  resetApp: () => {
    return initialState;
  },
};

const appSlice = createSlice({
  name: APP_SLICED_NAME,
  initialState,
  reducers,
});

const { setFlavor, setFlavorUrl, setSubApps, resetApp } = appSlice.actions;
const selectFlavor = (state) => state.app.flavor;
const selectFlavorUrl = (state) => state.app.flavorUrl;
const selectSubApps = (state) => state.app.subApps;
const appReducer = appSlice.reducer;

export { setFlavor, setFlavorUrl, setSubApps, resetApp, selectFlavor, selectFlavorUrl, selectSubApps, appReducer };
