import { useState, useEffect } from 'react';
import getPageLoadDetails from '@vac/page-load-details';
import useInterval from 'use-interval';
import { sendLog } from '../logging/Logging';

const initialPerfData = { name: 'Pending...' };

export const usePageLoadLogger = (isActive = true) => {
  const [perfData, setPerfData] = useState(initialPerfData);
  const [loadTimeDataReady, setLoadTimeDataReady] = useState(false);

  useInterval(
    () => {
      const perfData = getPageLoadDetails();
      if (perfData.timeToDomComplete) {
        setLoadTimeDataReady(true);
      }
    },
    !isActive || loadTimeDataReady ? null : 1000
  );

  useEffect(() => {
    if (loadTimeDataReady) {
      const perfData = getPageLoadDetails();
      setPerfData(perfData);
      sendLog(perfData);
    }
  }, [loadTimeDataReady]);

  return perfData;
};
