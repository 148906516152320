import { GLOBAL_NAV_TOP_LEVEL_MENU_ITEMS as MENU_ITEMS } from './global-navigation-top-level-menu';

const GLOBAL_NAV_CATEGORY_TYPES = [
  {
    id: '1',
    topLevelMenu: MENU_ITEMS.YOUR_TOOLS,
    innerLevelMenu: 'Virtual agent',
  },
  {
    id: '2',
    topLevelMenu: MENU_ITEMS.YOUR_TOOLS,
    innerLevelMenu: 'Contact center',
  },
  {
    id: '3',
    topLevelMenu: MENU_ITEMS.YOUR_TOOLS,
    innerLevelMenu: 'All channels',
  },
  {
    id: '4',
    topLevelMenu: MENU_ITEMS.YOUR_TOOLS,
    innerLevelMenu: "Your company's details",
  },
  {
    id: '5',
    topLevelMenu: MENU_ITEMS.YOUR_TOOLS,
    innerLevelMenu: 'Partners',
  },
  {
    id: '6',
    topLevelMenu: MENU_ITEMS.YOUR_TOOLS,
    innerLevelMenu: 'Developer tools',
  },
  {
    id: '8',
    topLevelMenu: MENU_ITEMS.ANALYTICS,
    innerLevelMenu: 'Analytics',
  },
];

export { GLOBAL_NAV_CATEGORY_TYPES };
