import isEqual from 'lodash/isEqual';

/**
 * Evaluate the user partner type and required partner type
 *
 * @export
 * @param {string} userPartnerType
 * @param {string} requiredPartnerType
 * @returns boolean
 */
export default function evaluatePartnerType(userPartnerType, requiredPartnerTypes = []) {
  if (!requiredPartnerTypes.length) {
    return true;
  }

  return !!requiredPartnerTypes.find((requiredPartnerType) => isEqual(userPartnerType, requiredPartnerType));
}
