import { createSlice } from '@reduxjs/toolkit';

const EG_PARTNER_LIST_SLICE_NAME = 'egPartnerList';

const initialState = [];

const reducers = {
  loadEgPartnerList: (state, { payload }) => {
    return payload;
  },
  resetEgPartnerList: () => {
    return initialState;
  },
};

const egPartnerListSlice = createSlice({
  name: EG_PARTNER_LIST_SLICE_NAME,
  initialState,
  reducers,
});

const { loadEgPartnerList, resetEgPartnerList } = egPartnerListSlice.actions;
const selectEgPartnerList = (state) => state.egPartnerList;
const egPartnerListReducer = egPartnerListSlice.reducer;

export { loadEgPartnerList, resetEgPartnerList, selectEgPartnerList, egPartnerListReducer };
