import React from 'react';
import { selectEgPartnerList } from '@cpce/console-web-store';

import { onEgPartnerListChanged } from '..';

export const useEgPartnerList = ({ useState, useEffect } = React) => {
  const [egPartnerList, setEgPartnerList] = useState([]);

  const setData = (updatedData) => {
    setEgPartnerList(updatedData);
  };

  useEffect(() => {
    const unsubscribe = onEgPartnerListChanged(setData, selectEgPartnerList);

    return () => {
      unsubscribe();
    };
  }, []);

  return egPartnerList;
};
