import { RESOURCE_ID_PATH, RESOURCE_TYPE_PATH, API_TYPE_ENDPOINTS } from '../constants';
import { apiRequest, processApiResponse, generateUrlBasedOnConfigKey } from '../helpers/helpers';

const generateResourceManagementUrl = (templateUrlPath, urlPathList, queryStringList) =>
  generateUrlBasedOnConfigKey(API_TYPE_ENDPOINTS.RESOURCE_MANAGEMENT_ENDPOINT, templateUrlPath, urlPathList, queryStringList);

export const getResourceById = async ({ partnerAccountId, resourceId, resourceType, containerUri }) => {
  const url = generateResourceManagementUrl(RESOURCE_ID_PATH, { resourceId, resourceType }, { partnerAccountId, container: containerUri });

  return await processApiResponse(await apiRequest({ url }));
};

export const getResourceByType = async ({ resourceType, containerUri }) => {
  const url = generateResourceManagementUrl(RESOURCE_TYPE_PATH, { resourceType }, { container: containerUri });

  return processApiResponse(await apiRequest({ url }));
};
