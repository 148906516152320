import { createSlice } from '@reduxjs/toolkit';

const ROLE_PREFERENCES_SLICE_NAME = 'rolePreferences';

const initialState = {
  isLoaded: true,
  rolePreferences: {},
};

const reducers = {
  setRolePreferences: (state, { payload }) => {
    const { isLoaded, rolePreferences } = payload;
    state.isLoaded = isLoaded;
    state.rolePreferences = rolePreferences;
  },
  setIsRolePreferencesLoaded: (state, { payload: { isLoaded } }) => {
    state.isLoaded = isLoaded;
  },
  resetRolePreferences: () => {
    return initialState;
  },
};

const rolePreferencesSlice = createSlice({
  name: ROLE_PREFERENCES_SLICE_NAME,
  initialState,
  reducers,
});

const { setRolePreferences, setIsRolePreferencesLoaded, resetRolePreferences } = rolePreferencesSlice.actions;
const selectRolePreferences = (state) => state.rolePreferences;
const rolePreferencesReducer = rolePreferencesSlice.reducer;

export { setRolePreferences, setIsRolePreferencesLoaded, resetRolePreferences, selectRolePreferences, rolePreferencesReducer };
