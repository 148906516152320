import URITemplate from 'urijs/src/URITemplate';
import jwtDecode from 'jwt-decode';
import get from 'lodash/get';
import {
  addAuthorizedRoute as setAuthorizedSubApp,
  authorize as userAuthorize,
  loadTrustedPartners,
  loadEgPartnerList,
  login as userLogin,
  logout as userLogout,
  makeApiRequestThunk,
  partnerSwitch as userPartnerSwitch,
  receiveTokens,
  resetApp,
  resetApiProgressCounter,
  resetRolePreferences,
  resetToken,
  resetTrustedPartners,
  resetUi,
  resetUserPreferences,
  selectToken,
  selectUser,
  setConfirmPartnerSwitch,
  store,
  selectFeatureToggles,
} from '@cpce/console-web-store';
import { resetAppStates, evaluatePermissions } from '@cpce/console-web-app-module';
import { setItemToLocalStorage, getItemFromLocalStorage, removeItemFromLocalStorage } from '@cpce/console-web-utilities';
import { apiRequestDefaultHeaders, HTTP_METHODS } from '@cpce/console-web-api-module';

export const APP_CLIENT_ID = 'appClientId';

export function getAppClientId(appClientId) {
  return getItemFromLocalStorage(APP_CLIENT_ID) || appClientId;
}

function getTokenDateTimeProperties(token) {
  let parsedToken;
  const invalidTokenDateTimeProperties = { exp: 0, iat: 0 };
  try {
    parsedToken = jwtDecode(token);
  } catch (error) {
    return invalidTokenDateTimeProperties;
  }

  if (!token || !token.length || !parsedToken || !Object.keys(parsedToken).length) {
    return invalidTokenDateTimeProperties;
  }

  const { exp, iat } = parsedToken;
  return { exp, iat };
}

function evaluateCPaaSPermission(userPermissions) {
  return evaluatePermissions(userPermissions, ['cpaasmanagement:manage']);
}

export async function login(authCode, configData) {
  const { BACK_END_LOGIN_ENDPOINT, CONSOLE_LOGIN_PATH, IAM_APP_CLIENT_ID } = configData;
  const url = BACK_END_LOGIN_ENDPOINT,
    redirectUrl = `${window.location.origin}${CONSOLE_LOGIN_PATH}`,
    payload = {
      clientId: getAppClientId(IAM_APP_CLIENT_ID),
      grantType: 'authorization_code',
      code: authCode,
      redirectUrl,
    };

  // Note: This dispatch thunk call doesn't need to implement `try catch` since it's been handled by the thunk function that would
  // return error when it catches the exception
  const response = await apiRequestDefaultHeaders({
    url,
    payload,
    method: HTTP_METHODS.POST,
    customStatusMessage: {
      rejectedMessage: 'Unable to log in. Please try again',
    },
    loadingScreenMessage: 'Logging in...',
  });

  const { error, data } = response;
  const responseData = get(response, 'payload.data');
  if (error) {
    throw new Error(data);
  }

  const {
    idToken,
    refreshToken,
    tokenExp,
    tokenIssuedAt,
    userName,
    userId,
    userResourceUri,
    partnerAccountId,
    roleAssignmentId,
    userPermissions,
    profile,
    trustedPartners,
  } = responseData;

  const hasCPaaSManagementPermission = evaluateCPaaSPermission(userPermissions);
  store.dispatch(receiveTokens({ idToken, refreshToken, tokenExp, tokenIssuedAt }));
  store.dispatch(
    userLogin({
      userName,
      userId,
      userResourceUri,
      partnerAccountId,
      roleAssignmentId,
      userPermissions,
      profile,
      hasCPaaSManagementPermission,
    })
  );

  if (trustedPartners) {
    trustedPartners.push({ id: profile.partnerId, partnerName: profile.partnerName });
    store.dispatch(loadTrustedPartners(trustedPartners));
  }
}

export async function logout(configData) {
  const { BACK_END_LOGOUT_ENDPOINT, IAM_APP_CLIENT_ID } = configData;
  const appClientId = getAppClientId() || IAM_APP_CLIENT_ID;
  const url = new URITemplate(BACK_END_LOGOUT_ENDPOINT).expand({ appClientId });
  const response = await apiRequestDefaultHeaders({ url });

  store.dispatch(resetToken());
  store.dispatch(userLogout());
  resetAppStates();
  store.dispatch(resetUserPreferences());
  store.dispatch(resetRolePreferences());

  const { error, data } = response;
  const responseData = get(response, 'payload.data');
  if (error) {
    throw new Error(data);
  }

  return responseData.result;
}

export async function refreshTokens(token, configData) {
  const { idToken, refreshToken } = token;
  const { BACK_END_TOKEN_REFRESH_ENDPOINT, IAM_APP_CLIENT_ID } = configData,
    payload = {
      clientId: getAppClientId() || IAM_APP_CLIENT_ID,
      grantType: 'refresh_token',
      idToken,
      refreshToken,
    },
    url = BACK_END_TOKEN_REFRESH_ENDPOINT;

  const response = await store.dispatch(
    makeApiRequestThunk({
      store,
      apiRequestOptions: {
        url,
        payload,
        method: HTTP_METHODS.POST,
      },
      customStatusMessage: {
        rejectedMessage: 'Unable to refresh token. Please try again',
      },
      isLoadingUiOn: false,
    })
  );

  const { error, data } = response;
  const responseData = get(response, 'payload.data');
  if (error) {
    throw new Error(data);
  }

  const { idToken: newIdToken, refreshToken: newRefreshToken } = responseData,
    { exp, iat } = getTokenDateTimeProperties(newIdToken);

  store.dispatch(receiveTokens({ idToken: newIdToken, refreshToken: newRefreshToken, tokenExp: exp, tokenIssuedAt: iat }));
}

export function setAppClientId(appClientId) {
  setItemToLocalStorage(APP_CLIENT_ID, appClientId);
}

export function removeAppClientId() {
  removeItemFromLocalStorage(APP_CLIENT_ID);
}

export function setUserAuthorization(authorized, bookmarkPathKey) {
  store.dispatch(userAuthorize({ authorized }));
  removeItemFromLocalStorage(bookmarkPathKey);
}

export function setPartnerSwitchConfirmation(partnerId) {
  store.dispatch(setConfirmPartnerSwitch(partnerId));
}

export async function partnerSwitch(partnerAccountId, configData) {
  const currentUser = selectUser(store.getState());
  const featureToggles = selectFeatureToggles(store.getState());
  const isRevertingToOriginalUser = partnerAccountId === currentUser.profile.partnerId;

  if (isRevertingToOriginalUser) {
    const originalUser = { profile: { ...currentUser.profile }, userPermissions: [...currentUser.permissions] };
    store.dispatch(userPartnerSwitch(originalUser));
  } else {
    const { BACK_END_FETCH_PARTNER_DETAILS_ENDPOINT, BACK_END_EG_IDENTITY_FETCH_PARTNER_DETAILS_ENDPOINT } = configData;
    const url =
      featureToggles?.toggles?.isEGIdentitySupportEnabled && currentUser?.isEGLoginSession
        ? BACK_END_EG_IDENTITY_FETCH_PARTNER_DETAILS_ENDPOINT
        : BACK_END_FETCH_PARTNER_DETAILS_ENDPOINT;
    const payload = {
      partnerId: partnerAccountId,
    };

    const response = await store.dispatch(
      makeApiRequestThunk({
        store,
        apiRequestOptions: {
          url,
          payload,
          method: HTTP_METHODS.POST,
        },
        customStatusMessage: {
          rejectedMessage: 'Unable to fetch partner details',
        },
        loadingScreenMessage: 'Fetching partner details...',
      })
    );

    const { error, data } = response;
    const responseData = get(response, 'payload.data');
    if (error) {
      throw new Error(data);
    }

    store.dispatch(userPartnerSwitch(responseData));
  }
}

export function addAuthorizedRoute(routes) {
  store.dispatch(setAuthorizedSubApp(routes));
}

const AUTH_FLOW_KEY = 'authFlow';
export const AUTH_IDENTITY_FLOWS = Object.freeze({
  NONE: 'NONE',
  IAM_COGNITO: 'IAM_COGNITO',
  IAM_OKTA: 'IAM_OKTA',
  EG_IDENTITY: 'EG_IDENTITY',
});

export function getAuthFlowType() {
  const authFlowType = getItemFromLocalStorage(AUTH_FLOW_KEY);
  if (authFlowType && Object.values(AUTH_IDENTITY_FLOWS).includes(authFlowType)) {
    return authFlowType;
  }
  setAuthFlowType(AUTH_IDENTITY_FLOWS.NONE);
  return AUTH_IDENTITY_FLOWS.NONE;
}

export function setAuthFlowType(authFlowType) {
  setItemToLocalStorage(AUTH_FLOW_KEY, authFlowType);
}

// These below are for EG Identity Auth flow
export async function exchangeAuthCode(url, { authCode, redirectUrl }) {
  const payload = {
    authCode,
    redirectUrl,
  };

  // To set the custom header to bypass CGP layer
  const bypassHeader = { 'x-bypass-cgp': true };

  const response = await apiRequestDefaultHeaders({
    url,
    payload,
    method: HTTP_METHODS.POST,
    additionalHeaders: bypassHeader,
    customStatusMessage: {
      rejectedMessage: 'Unable to log in. Please try again',
    },
    loadingScreenMessage: 'Logging in...',
  });

  const { error, data } = response;
  if (error) {
    throw new Error(data);
  }
}

export async function getLoggedInUser(url) {
  const response = await apiRequestDefaultHeaders({
    url,
    customStatusMessage: {
      rejectedMessage: 'Unable to get user data. Please try again',
    },
    loadingScreenMessage: 'Getting user data...',
  });

  const {
    error,
    data,
    payload: { data: responseData },
  } = response;
  if (error) {
    throw new Error(data);
  }

  const { userName, userId, userResourceUri, partnerAccountId, roleAssignmentId, userPermissions, profile, trustedPartners } = responseData;
  store.dispatch(userLogin({ userName, userId, userResourceUri, partnerAccountId, roleAssignmentId, userPermissions, profile }));

  if (trustedPartners) {
    const { partnerId, partnerName } = profile;
    trustedPartners.push({ id: partnerId, partnerName: partnerName });
    store.dispatch(loadTrustedPartners(trustedPartners));
  }
}

export async function egLogin(authCode, configData, state, featureTogglesList) {
  const { BACK_END_EG_LOGIN_ENDPOINT, FEDERATED_SESSION_START_TIMESTAMP, FEDERATED_SESSION_AUTH_CODE } = configData;

  const payload = {
    authCode,
    state,
  };

  const bypassHeader = { 'x-bypass-cgp': true };

  const response = await apiRequestDefaultHeaders({
    url: BACK_END_EG_LOGIN_ENDPOINT,
    payload,
    method: HTTP_METHODS.POST,
    additionalHeaders: bypassHeader,
    customStatusMessage: {
      rejectedMessage: 'Unable to log in. Please try again',
    },
    loadingScreenMessage: 'Logging in...',
  });

  const { error, data } = response;
  const responseData = get(response, 'payload.data');
  if (error) {
    throw new Error(data);
  }

  const {
    idToken,
    timeToLive,
    tokenExp,
    tokenIssuedAt,
    userName,
    userId,
    userResourceUri,
    partnerAccountId,
    roleAssignmentId,
    userPermissions,
    profile,
    trustedPartners,
    egPartnerList,
  } = responseData;

  const hasCPaaSManagementPermission = evaluateCPaaSPermission(userPermissions);

  setItemToLocalStorage(FEDERATED_SESSION_AUTH_CODE, authCode);
  setItemToLocalStorage(FEDERATED_SESSION_START_TIMESTAMP, Date.now());
  store.dispatch(receiveTokens({ idToken, tokenExp, tokenIssuedAt, opaqueTokenExp: timeToLive }));

  const isEGLoginSession = state === 'eglogin1' || state === 'eglogin2';

  const extendedUserState = featureTogglesList?.isEGIdentitySupportEnabled
    ? {
        isFederatedSession: !isEGLoginSession,
        isEGLoginSession,
        overrideIsLoggedIn: featureTogglesList?.isEGIdentityPartnerSelectionEnabled
          ? state === 'eglogin1'
            ? false
            : state === 'eglogin2'
            ? true
            : null
          : null,
      }
    : {
        isEGLoginSession: false,
      };

  store.dispatch(
    userLogin({
      userName,
      userId,
      userResourceUri,
      partnerAccountId,
      roleAssignmentId,
      userPermissions,
      profile,
      isFederatedSession: true,
      hasCPaaSManagementPermission,
      ...extendedUserState,
    })
  );

  if (featureTogglesList?.isEGIdentitySupportEnabled && isEGLoginSession && trustedPartners) {
    const trustedPartnersExtended = [...trustedPartners, { id: profile.partnerId, partnerName: profile.partnerName }];
    store.dispatch(loadTrustedPartners(trustedPartnersExtended));
  }

  if (featureTogglesList?.isEGIdentitySupportEnabled && isEGLoginSession && egPartnerList) {
    store.dispatch(loadEgPartnerList(egPartnerList));
  }
}

export async function egLogout(configData, clearSessionBeforeLogin) {
  const { BACK_END_EG_LOGOUT_ENDPOINT, FEDERATED_SESSION_AUTH_CODE, FEDERATED_SESSION_START_TIMESTAMP } = configData;
  const loadingScreenMessage = clearSessionBeforeLogin ? 'Clearing session...' : 'Redirecting to open world...';

  const response = await apiRequestDefaultHeaders({
    url: BACK_END_EG_LOGOUT_ENDPOINT,
    loadingScreenMessage,
  });

  store.dispatch([
    resetUi(),
    resetApp(),
    resetApiProgressCounter(),
    resetToken(),
    userLogout(),
    resetTrustedPartners(),
    resetUserPreferences(),
    resetRolePreferences(),
  ]);
  removeItemFromLocalStorage(FEDERATED_SESSION_AUTH_CODE);
  removeItemFromLocalStorage(FEDERATED_SESSION_START_TIMESTAMP);

  const { error, data } = response;
  const responseData = get(response, 'payload.data');
  if (error) {
    throw new Error(data);
  }

  return responseData.result;
}

export async function refreshEgPrincipalToken(configData) {
  const response = await apiRequestDefaultHeaders({
    url: configData?.BACK_END_REFRESH_EG_PRINCIPAL_TOKEN,
    method: HTTP_METHODS.POST,
    customStatusMessage: {
      rejectedMessage: 'Unable to refresh eg principal token.',
    },
    isLoadingUiOn: false,
  });

  const { error, data } = response;
  const responseData = get(response, 'payload.data');

  if (error) {
    throw new Error(data);
  }

  const currEgPrincipalTokenDetails = selectToken(store.getState());
  responseData?.idToken && store.dispatch(receiveTokens({ ...currEgPrincipalTokenDetails, ...responseData }));
}
