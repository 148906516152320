import { store, makeApiRequestThunk, selectToken, selectUser } from '@cpce/console-web-store';
import { HTTP_METHODS } from '@cpce/console-web-core-api';
import { getItemFromLocalStorage } from '@cpce/console-web-utilities';

export default async function apiRequest({
  baseUrl,
  url,
  method = HTTP_METHODS.GET,
  headers,
  payload,
  responseType,
  timeout,
  uploadProgressHandler,
  downloadProgressHandler,
  cancelToken,
  customStatusMessage: {
    bannerType = '',
    bannerNote = '',
    fulfilledMessage = '',
    rejectedMessage = '',
    showUnderlyingError = true,
    showError = true,
  } = {},
  loadingScreenMessage = '',
  isLoadingUiOn = true,
}) {
  const state = store.getState();
  const token = selectToken(state);
  const user = selectUser(state);
  const { isLoggedIn, isFederatedSession } = user;

  const MILLIS_PER_SECOND = 1000;

  const checkTokenStatus = (token) => {
    const timestampInSeconds = Math.floor(Date.now() / MILLIS_PER_SECOND);
    const timeBeforeTokenExpiresInSeconds = token.exp - timestampInSeconds;
    return timeBeforeTokenExpiresInSeconds > 0;
  };

  const checkFederatedSessionTokenStatus = (token) => {
    const currTimestampInMillis = Date.now();
    const opaqueTokenStartTimestampInMillis = getItemFromLocalStorage('federatedSessionStartTimestamp');
    const opaqueTokenTimePassedInMillis = currTimestampInMillis - opaqueTokenStartTimestampInMillis;
    const opaqueTokenExpiresInMillis = token?.opaqueTokenExp * MILLIS_PER_SECOND - opaqueTokenTimePassedInMillis;
    return opaqueTokenExpiresInMillis > 0;
  };

  if (isFederatedSession) {
    if (!url.includes('logout') && isLoggedIn && token?.opaqueTokenExp && !checkFederatedSessionTokenStatus(token)) {
      return;
    }
  } else {
    if (!url.includes('logout') && isLoggedIn && token?.exp && !checkTokenStatus(token)) {
      window.history.replaceState('', 'logout', '/logout');
      return;
    }
  }

  return await store.dispatch(
    makeApiRequestThunk({
      store,
      apiRequestOptions: {
        baseUrl,
        url,
        method,
        headers,
        payload,
        responseType,
        timeout,
        uploadProgressHandler,
        downloadProgressHandler,
        cancelToken,
      },
      customStatusMessage: {
        bannerType,
        bannerNote,
        fulfilledMessage,
        rejectedMessage,
        showUnderlyingError,
        showError,
      },
      loadingScreenMessage,
      isLoadingUiOn,
    })
  );
}

export const BANNER_TYPES = Object.freeze({
  ATTENTION: 'attention',
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  TIP: 'tip',
});
