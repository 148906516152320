import { useEvent } from 'react-use';
import singleSpa from 'single-spa';
import { isEmpty } from 'lodash';

const SINGLE_SPA_APPLICATION_PREFIX = 'single-spa-application:';
const NONE = 'none';

function getAppNamesByStatus(newAppStatuses) {
  let unmountedAppName, mountedAppName;

  for (const key in newAppStatuses) {
    const currMountStatus = newAppStatuses[key];
    if (!unmountedAppName && currMountStatus === singleSpa.NOT_MOUNTED) {
      unmountedAppName = key;
    }

    if (!mountedAppName && currMountStatus === singleSpa.MOUNTED) {
      mountedAppName = key;
    }

    if (unmountedAppName && mountedAppName) {
      break;
    }
  }

  return { unmountedAppName, mountedAppName };
}

const unloadTheNotMountedAppAfterRedirect = async ({ detail: { newAppStatuses } }, subAppsWithoutNavBar) => {
  if (isEmpty(newAppStatuses)) return;
  const { unmountedAppName, mountedAppName } = getAppNamesByStatus(newAppStatuses);
  const rootAppDiv = document.getElementById(`${SINGLE_SPA_APPLICATION_PREFIX}root-app`);
  const mountedAppDiv = document.getElementById(`${SINGLE_SPA_APPLICATION_PREFIX}${mountedAppName}`);

  if (subAppsWithoutNavBar.includes(unmountedAppName)) {
    rootAppDiv && (rootAppDiv.style.display = NONE);
    mountedAppDiv && (mountedAppDiv.style.display = NONE);
    window.location.reload();
  } else {
    unmountedAppName && (await singleSpa.unloadApplication(unmountedAppName));
  }
};

export const useSubAppLifeCycle = async (subAppsWithoutNavBar) => {
  const handleBeforeRoutingEvent = async (event) => {
    await unloadTheNotMountedAppAfterRedirect(event, subAppsWithoutNavBar);
  };

  await useEvent('single-spa:before-routing-event', handleBeforeRoutingEvent);
};
