import { store } from '@cpce/console-web-store';
import { navigationItemsSelector } from '../helpers';

/**
 * Get Navigation Item objects to be consumed by NavBar
 *
 * @export
 * @returns objects as an array that contains nav bar links data
 */
export default function getNavigationItems() {
  return navigationItemsSelector(store.getState());
}
