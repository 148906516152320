import * as React from 'react';
import { getCurrentSubApp } from '@cpce/console-web-app-module';
import { getRemoteConfig } from '../getRemoteConfig/getRemoteConfig';
import getConfig from '../config-helper/configHelper';

const CONFIG_FETCH_STATUS = {
  PENDING: 'pending',
  FETCHING: 'fetching',
  COMPLETED: 'completed',
  SKIPPED: 'skipped',
};

export const useConfig = ({ useState, useEffect, useMemo } = React, configList, overrideConfig) => {
  const [remoteConfig, setRemoteConfig] = useState({});
  const [configFetchStatus, setConfigFetchStatus] = useState(CONFIG_FETCH_STATUS.PENDING);

  const isConfigFetched = [CONFIG_FETCH_STATUS.SKIPPED, CONFIG_FETCH_STATUS.COMPLETED].indexOf(configFetchStatus) !== -1;
  const localConfig = useMemo(() => getConfig(configList, overrideConfig), [configList, overrideConfig]);

  useEffect(() => {
    const fetchRemoteConfig = async (appId) => {
      const remoteConfig = await getRemoteConfig(appId);
      setRemoteConfig(remoteConfig);
      setConfigFetchStatus(CONFIG_FETCH_STATUS.COMPLETED);
    };

    const { id: appId } = getCurrentSubApp() || {};

    if (appId) {
      setConfigFetchStatus(CONFIG_FETCH_STATUS.FETCHING);
      fetchRemoteConfig(appId);
    } else {
      setConfigFetchStatus(CONFIG_FETCH_STATUS.SKIPPED);
    }
  }, []);

  return {
    config: isConfigFetched ? { ...localConfig, ...remoteConfig } : { ...localConfig },
    isFetchingConfigInProgress: !isConfigFetched,
  };
};
