import { isPlainObject, isEmpty, isString } from 'lodash';
import {
  setStatus,
  setLoadingScreenMessage,
  resetLoadingScreenMessage,
  apiRequestInProgressCounter,
  apiRequestFulfilledCounter,
} from '../../';
import { setCapturedErrors } from '../../../helpers';

const successStatusType = 'success',
  errorStatusType = 'error',
  keyMappingFallbackRejectedMessage = 'general',
  traceId = 'trace-id';

const htmlResponseRegex = /^(<!doctype html>)?[\n\r\s]*<html[^>]*>/i;
const getHttpMethodRegex = /^get$/i;

const parseErrorData = ({ response: { data, status, statusText }, message }) => {
  const downstreamResponseDataIsHtml = htmlResponseRegex.test(data);
  const downstreamResponseErrorMessage = downstreamResponseDataIsHtml ? statusText : data;

  const actualErrorMessages = [message, downstreamResponseErrorMessage].filter(Boolean);
  if (downstreamResponseErrorMessage !== data) {
    actualErrorMessages.push(data);
  }

  return {
    status,
    actualErrorMessages,
  };
};

const generateErrorMessage = (error, customErrorMessage, showUnderlyingError) => {
  const { status, actualErrorMessages } = parseErrorData(error);
  let errorMessage;

  if (customErrorMessage) {
    if (isString(customErrorMessage)) {
      errorMessage = customErrorMessage;
    }

    if (isPlainObject(customErrorMessage) && !isEmpty(customErrorMessage)) {
      errorMessage = customErrorMessage[status] || customErrorMessage[keyMappingFallbackRejectedMessage];
    }
  }

  if (isEmpty(errorMessage)) {
    errorMessage = actualErrorMessages.find((actualErrorMessage) => !isEmpty(actualErrorMessage));
  }

  return {
    message: errorMessage,
    actualErrorMessages: showUnderlyingError
      ? actualErrorMessages
          .filter((actualErrorMessage) => !isEmpty(actualErrorMessage) && actualErrorMessage !== errorMessage)
          .map((actualErrorMessage) => (isPlainObject(actualErrorMessage) ? JSON.stringify(actualErrorMessage) : actualErrorMessage))
      : null,
  };
};

const createStatus = ({ message, error, bannerNote, bannerType }) => {
  const baseStatus = {
    bannerType: error ? (bannerType ? bannerType : errorStatusType) : bannerType ? bannerType : successStatusType,
    bannerNote,
    visible: true,
  };
  return isPlainObject(message)
    ? {
        ...baseStatus,
        ...message,
      }
    : {
        ...baseStatus,
        message,
      };
};

const createError = ({ errorResp, apiRequestOptions, responseHeaders }) => {
  return {
    error: errorResp.toString(),
    url: apiRequestOptions.url,
    requestTraceId: apiRequestOptions.headers[traceId],
    responseTraceId: responseHeaders[traceId],
    method: apiRequestOptions.method,
  };
};

const processResponse = (store, customFulfilledMessage, customBannerNote, customBannerType, httpMethod, isLoadingUiOn = true) => {
  if (isLoadingUiOn) {
    store.dispatch(apiRequestFulfilledCounter());
  }
  if (!isEmpty(customFulfilledMessage) && !getHttpMethodRegex.test(httpMethod)) {
    store.dispatch(
      setStatus(createStatus({ message: customFulfilledMessage, bannerNote: customBannerNote, bannerType: customBannerType }))
    );
  }
};

const processErrorResponse = (
  store,
  errorResp,
  customRejectedMessage,
  customBannerNote,
  customBannerType,
  showUnderlyingError,
  showError = true,
  apiRequestOptions,
  responseHeaders
) => {
  if (showError) {
    const errorMessage = generateErrorMessage(errorResp, customRejectedMessage, showUnderlyingError);
    setCapturedErrors(store, createError({ errorResp, apiRequestOptions, responseHeaders }));
    store.dispatch(
      setStatus(createStatus({ message: errorMessage, error: true, bannerNote: customBannerNote, bannerType: customBannerType }))
    );
  }
};

const processLoadingScreenAction = (store, loadingScreenMessage, loadingScreenAction) => {
  if (isEmpty(loadingScreenMessage)) {
    return;
  }
  store.dispatch(loadingScreenAction);
};

const processPreRequest = ({ store, loadingScreenMessage, isLoadingUiOn = true }) => {
  processLoadingScreenAction(store, loadingScreenMessage, setLoadingScreenMessage(loadingScreenMessage));
  if (isLoadingUiOn) {
    store.dispatch(apiRequestInProgressCounter());
  }
};

const processPostRequest = ({ store, loadingScreenMessage }) => {
  processLoadingScreenAction(store, loadingScreenMessage, resetLoadingScreenMessage());
};

export { processResponse, processErrorResponse, processPreRequest, processPostRequest };
