import { store, selectFlavor } from '@cpce/console-web-store';
import { FLAVOR_FOR_ENVIRONMENT } from '../constants/flavor-for-environment';

/**
 * Returns the current effective app's environment / flavor
 *
 * @export
 * @returns string - one of a current flavor 'lab', 'dev', 'int', 'demo' or 'prod'
 */
export default function getFlavor() {
  let currentFlavor = selectFlavor(store.getState());

  if (!currentFlavor) {
    currentFlavor = FLAVOR_FOR_ENVIRONMENT[window.location.origin] || 'dev';
  }

  return currentFlavor;
}
