import { createSlice } from '@reduxjs/toolkit';

const FEATURE_TOGGLES_NAME = 'featureToggles';

const initialState = {
  toggles: {},
};

const reducers = {
  setFeatureToggles: (state, { payload: { toggles } }) => {
    state.toggles = toggles;
  },
};

const featureTogglesSlice = createSlice({
  name: FEATURE_TOGGLES_NAME,
  initialState,
  reducers,
});

const { setFeatureToggles } = featureTogglesSlice.actions;
const selectFeatureToggles = (state) => state.featureToggles;
const featureTogglesReducer = featureTogglesSlice.reducer;

export { setFeatureToggles, selectFeatureToggles, featureTogglesReducer };
