import { createSlice } from '@reduxjs/toolkit';

const ERRORS_SLICE_NAME = 'errors';

const initialState = [];

const reducers = {
  setErrors: (state, { payload }) => {
    return payload;
  },
  resetErrors: () => {
    return initialState;
  },
};

const errorsSlice = createSlice({
  name: ERRORS_SLICE_NAME,
  initialState,
  reducers,
});

export const selectErrors = (state) => state.errors;
export const errorsReducer = errorsSlice.reducer;
export const { setErrors, resetErrors } = errorsSlice.actions;
