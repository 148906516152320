import { isEqual } from 'lodash';

export function observeDataChanges(store, selector, onChangeCallbackFn) {
  let currentState;

  function handleChange() {
    let nextState = selector(store.getState());
    if (!isEqual(nextState, currentState)) {
      currentState = nextState;
      onChangeCallbackFn(currentState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}
