import { combineReducers } from '@reduxjs/toolkit';

// Reducers
import {
  userReducer,
  userPreferencesReducer,
  rolePreferencesReducer,
  apiReducer,
  uiReducer,
  tokenReducer,
  appReducer,
  featureTogglesReducer,
  trustedPartnersReducer,
  egPartnerListReducer,
  errorsReducer,
} from '../slices';

const rootReducer = combineReducers({
  user: userReducer,
  api: apiReducer,
  ui: uiReducer,
  token: tokenReducer,
  app: appReducer,
  featureToggles: featureTogglesReducer,
  trustedPartners: trustedPartnersReducer,
  egPartnerList: egPartnerListReducer,
  userPreferences: userPreferencesReducer,
  rolePreferences: rolePreferencesReducer,
  errors: errorsReducer,
});

export default rootReducer;
