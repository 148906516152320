// Ref: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
// sanitizedStoreSlices contains all the sanitized store slices that would be enforced on making
// them become invisible on the devlTools extension
const sanitizedStoreSlices = ['token', 'app'];
// sanitizedActions contains all excluded actions for the store.
const sanitizedActions = ['api/makeApiRequest/fulfilled', 'token/receiveTokens'];

const sanitizedStoreSliceProps = sanitizedStoreSlices.reduce((sanitizedStateProps, sanitizedSlice) => {
  return { ...sanitizedStateProps, [sanitizedSlice]: '<<LONG_BLOB>>' };
}, {});

const sanitizeState = (state) => (sanitizedStoreSlices.every((slice) => state[slice]) ? { ...state, ...sanitizedStoreSliceProps } : state);

export const reduxDevToolsOptionsForProduction = {
  features: {
    lock: true,
  },
  stateSanitizer: sanitizeState,
  actionsBlacklist: sanitizedActions,
};
