import React from 'react';
import { selectErrors } from '@cpce/console-web-store';
import { onErrorsChanged } from '..';

export const useErrors = ({ useState, useEffect } = React) => {
  const [errors, setErrors] = useState([]);

  const setData = (updatedData) => {
    setErrors(updatedData);
  };

  useEffect(() => {
    const unsubscribe = onErrorsChanged(setData, selectErrors);

    return () => {
      unsubscribe();
    };
  }, []);

  return errors;
};
