import { createSlice } from '@reduxjs/toolkit';

const USER_SLICE_NAME = 'user';

const initialState = {
  userId: '',
  userName: '',
  userResourceUri: '',
  isLoggedIn: false,
  role: '',
  isAuthorized: undefined,
  hasEgIdentityAppAccess: undefined,
  profile: {},
  permissions: [],
  activePartner: {
    profile: {},
    permissions: [],
  },
  authorizedRoutes: [],
  isFederatedSession: undefined,
  isEGLoginSession: undefined,
  hasCPaaSManagementPermission: undefined,
};

const reducers = {
  login: (state, { payload }) => {
    const {
      userId,
      userName,
      userResourceUri,
      roleAssignmentId,
      userPermissions,
      profile,
      isFederatedSession,
      hasCPaaSManagementPermission,
      isEGLoginSession,
      overrideIsLoggedIn = null,
    } = payload;
    state.userId = userId;
    state.userName = userName;
    state.userResourceUri = userResourceUri;
    state.isLoggedIn = overrideIsLoggedIn !== null ? overrideIsLoggedIn : true;
    state.role = roleAssignmentId;
    state.profile = profile;
    state.permissions = userPermissions;
    state.activePartner = {
      profile: profile,
      permissions: userPermissions,
    };
    state.isFederatedSession = isFederatedSession;
    state.isEGLoginSession = isEGLoginSession;
    state.hasCPaaSManagementPermission = hasCPaaSManagementPermission;
  },
  logout: () => {
    return initialState;
  },
  authorize: (state, { payload: { authorized } }) => {
    state.isAuthorized = authorized;
  },
  setHasEgIdentityAppAccess: (state, { payload: { hasEgIdentityAppAccess } }) => {
    state.hasEgIdentityAppAccess = hasEgIdentityAppAccess;
  },
  partnerSwitch: (state, { payload }) => {
    const { userPermissions, profile } = payload;
    state.activePartner = {
      profile: profile,
      permissions: userPermissions,
    };
    state.authorizedRoutes = [];
  },
  addAuthorizedRoute: (state, { payload }) => {
    state.authorizedRoutes = [...(state.authorizedRoutes || []), ...payload];
  },
};

const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers,
});

const { login, logout, authorize, partnerSwitch, addAuthorizedRoute, setHasEgIdentityAppAccess } = userSlice.actions;
const selectUser = (state) => state.user;
const userReducer = userSlice.reducer;

export { login, logout, authorize, partnerSwitch, addAuthorizedRoute, selectUser, userReducer, setHasEgIdentityAppAccess };
